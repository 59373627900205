/*
 *
 *   Workero Admin Page
 *   Copyright 2017 expertnetwork.ro
 *
*/


/* GLOBAL STYLES
-------------------------------------------------- */



.admin {
    background-color: $third-color;

    .logo-name {
        background: url('../images/workero015978034.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 65px;
        margin: 0 0 15px -5px;
    }
    &.login {
        background-color: #f3f3f4;
        .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
            background-color: $fourth-color;
            border-color: $fourth-color;
        }
        .logo-name {
            height: 110px;
            margin: 30px 0;
        }
    }
    .nav-header {
        background-image: none;
        background-color: $second-color;
    }
    .nav > li.active {
        border-left: 4px solid $fourth-color;
    }
    .navbar-minimalize{ 
        background-color: $fourth-color;
        border-color: $fourth-color;
    }
    #wrapper {
        border-bottom: 5px solid $fourth-color;
        padding-bottom: 30px;
    }

    
}


.supplier {
    background-color: $sthird-color;

    .logo-name {
        background: url('../images/ExpertNetwork_logo.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 95px;
        margin: 0 0 15px -5px;
    }
    &.login {
        background-color: #f3f3f4;
        .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
            background-color: $sfourth-color;
            border-color: $sfourth-color;
        }
        .logo-name {
            height: 160px;
            margin: 30px 0;
        }
    }
    .nav-header {
        background-image: none;
        background-color: $ssecond-color;
    }
    // .nav > li.active {
    //     border-left: 4px solid $sfourth-color;
    // }
    .navbar-minimalize{
        background-color: $sfourth-color;
        border-color: $sfourth-color;
    }
    #wrapper {
        border-bottom: 5px solid $sfourth-color;
        padding-bottom: 30px;
    }
    
}



