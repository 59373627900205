.welcome-top-message{
    font-family: $cera-medium;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    text-indent: 54px;
    color: #bbc1c6;
}
.workeroNavigationBlock{
    position: fixed;
    top:0px;
    width: 100%;
    z-index: 1000;
    background: #ffffff;
    height: 80px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
    .contentHolder{
        width: 100%;
        padding-left:100px;
    }
    .languageSwitch{
        img{
            height: 20px;
        }
    }
    .w-logo-img{
        text-align: center;
        line-height: 80px;
        vertical-align: middle;
        img{
            width: 46px;
        }
    }
    .w-logo-holder{
        width: 100px;
        height: 80px;
        float: left;
    }
    .w-search,
    .sidebarHolder{
        vertical-align: middle;
        line-height: 80px;
    }
    .w-search{
        form{
            line-height: 80px;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
        }
        .searchInput{

            &:before{
                content: '';
                display: block;
                width: 23px;
                height: 22px;
                background: url("../images/menu-items/icon-search.svg") no-repeat 50% 50%;
                position: absolute;
                left: 35px;
                top: 13px;
                pointer-events: none;
            }
            &:after{
                content: '';
                display: block;
                width: 18px;
                height: 16px;
                background: url("../images/menu-items/arrow.svg") no-repeat 50% 50%;
                position: absolute;
                right: 35px;
                top: 17px;
                pointer-events: none;
            }
            &:hover,
            &:active{
                &:before{
                    background: url("../images/menu-items/icon-search-hover.svg") no-repeat 50% 50%;
                }
                &:after{
                    background: url("../images/menu-items/arrow-hover.svg") no-repeat 50% 50%;
                }
            }
            &:active,
            &:focus{
                &:before{
                    width: 24px;
                    height: 25px;
                    top: 13px;
                    background: url("../images/menu-items/icon-search-active.svg") no-repeat 50% 50%;
                }
                &:after{
                    margin-top: 2px;
                    background: url("../images/menu-items/arrow-active.svg") no-repeat 50% 50%;
                }

            }
            &.disable{
                input{
                    background-image: linear-gradient(to top, #ffffff, #f7f7f7)!important;
                    border: solid 1px #ffffff!important;
                    @include placeholder-color(#bbc1c6);
                    cursor: default!important;
                    pointer-events: none;
                }
                &:before{
                    width: 23px;
                    height: 22px;
                    background: url("../images/menu-items/icon-search.svg") no-repeat 50% 50%;
                }
                &:after{

                    margin-top: 0px;
                    background: url("../images/menu-items/arrow-disable.svg") no-repeat 50% 50%!important;
                }
                &:hover,
                &:active,
                &:focus{
                    &:before{
                        width: 23px;
                        height: 22px;
                        background: url("../images/menu-items/icon-search.svg") no-repeat 50% 50%;
                    }
                    &:after{

                        background: url("../images/menu-items/arrow-disable.svg") no-repeat 50% 50%!important;
                    }

                }
            }

        }
        input{
            min-height: 50px;
            border-radius: 3px;
            background-image: linear-gradient(to top, #ffffff, #f7f7f7);
            border: solid 1px #ffffff;
            box-shadow: none;
            font-family: $cera-m;
            font-size: $size-l;
            font-weight: 500;
            text-align: left;
            text-indent: 54px;
            color: #bbc1c6;



            &:hover{
                border-radius: 3px;
                background-image: linear-gradient(to top, #ffffff, #f7f7f7);
                border: solid 1px #ffd87d;
                box-shadow: none;
                @include placeholder-color(#848e95);
                color: #848e95;

            }
            &:focus,
            &:active{
                background-image: linear-gradient(to top, #fffefa, #fffcf3);
                border: solid 1px #ffd87d;
                box-shadow: none;
                @include placeholder-color(#131e29);
                color: #131e29;
            }
        }
    }
}
.bellIcon{
    width:25px;
    height: 31px;
    display: inline-block;
    background-image: url("../images/svg/notifications.svg");
    vertical-align: middle;
    line-height: 80px;
}
