.ambassador{

  .mini-button, .expand-button{
    font-family: "CeraGR-Bold";
    font-size: 14px;
    color: #f0b323;
    &.not-shown{
      display: none;
    }
  }

  .expand-button:before{
    content: 'EXPAND';
  }

  .contact-list{
    &.not-shown{
      display: none;
    }
  }

  .list-selector{
    outline: none;
  }

  .list-selector-list{
    border: solid 1px #ffd87d;
    border-right: 2px solid #ffd87d;
    background: #fff;
    max-height: 300px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    min-width: 350px;
    top: 40px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.15);

    padding: 0;
    & > li{
      cursor: pointer;
      margin-left: 1px;
      list-style: none;
      display: flex;
      padding: 10px;
      justify-content: space-between;
      .close{
        display: none;
      }
      &:hover{
        background: #f5f5f5;
        color: #000;
        .close{
          display: inline-block;
        }
      }
    }
  }

  .expand-button:after{
    content: url(/fonts/expand-icon.svg);
    height: 3px;
    width: 6px;
    margin-left: 4px;
    margin-top: 2px;
    display: inline-block;
    position: absolute;
  }

  .expand-button.collapsed:before{
    content: 'COLLAPSE';
  }

  .expand-button.collapsed:after{
    content: url(/fonts/contract-icon.svg);
    height: 3px;
    width: 6px;
    margin-left: 4px;
    margin-top: 2px;
    display: inline-block;
    position: absolute;
  }

  .mt5{
    margin-top: 5px;
  }

  .ml5{
    margin-left: 5px;
  }

  .ml10{
    margin-left: 10px;
  }

  .ambassador-email-container{
    border: 1px solid #e8ebee;
    border-radius: 3px;

    .ambassador-email-header{
      background: #fffbf3;
      padding: 30px;
      display: flex;

      .downloadButton{
        border-radius: 3px;
        border: solid 2px #f0b323;
        font-family: "CeraGR-Bold";
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        text-align: left;
        color: #f0b323;
        background-image: transparent !important;
        background: transparent !important;
        padding: 12px 28px;
      }

      .sendButton{
        border-radius: 3px;
        border: solid 2px #f0b323;
        font-family: "CeraGR-Bold";
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        text-align: left;
        color: #f0b323;
        background-image: transparent !important;
        background: transparent !important;
        padding: 8px 28px;
      }

      .sendButton{
        background-color: #f0b323 !important;
        color: #fff;
      }

      .sendButton.disabled{
        background: #f2f2f2 !important;
        color: #d4d9db;
        border-color: #f2f2f2;
      }

      span.title{
        color: #000;
        font-size: 18px;
        font-weight: 600;
        display: block;
      }
    }

    .ambassador-email-content{
      padding: 30px;
      &:not(.collapsed){
        height: 0;
        padding: 0;
        overflow: hidden;
      }

      .sendToSection{
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        background: #f2f2f2;
        padding: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .sent{
    color: #00cc41;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 15px 5px 15px;
  }

  .resend-text{
    font-size: 12px;
    color: #b0b8bc;
    margin-top: 18px;
    text-align: right;
  }

  .sent:before{
    background-image: url('../images/svg/tipsCheck.svg');
    background-size: 20px 13px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 13px;
    content:"";
    margin-right: 5px;
  }

  .error-message{
    color: red;
    font-size: 14px;
    &.not-shown{
      display: none;
    }
  }

  .global-success-message{
      background-color: #00cc41;
      color: #fff;
      padding: 10px;
      font-size: 14px;
    .close-button{
      float: right;
      cursor: pointer;
      color: #00a937;
    }
  }

  .success-message{
    &.not-shown{
      display: none;
    }
    font-size: 14px;
    .filename{
      font-size: 22px;
      color: #000;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .amber{
      color: #f6b13d;
      font-weight: bold;
      cursor: pointer;
    }
    .remove{
      color: red;
      font-weight: bold;
      cursor: pointer;
    }
    .dot{
      display: inline-block;
      background: #9daab0;
      height: 4px;
      width: 4px;
      border-radius: 2px;
      margin: 3px 6px;
    }
  }

  .mt10{
    margin-top: 10px;
  }

  .mt20{
    margin-top: 20px;
  }

  .mb20{
    margin-bottom: 20px;
  }

  .panel-body
  {
    .row:last-child
    {
      padding-bottom: 30px;
    }
  }

}

.ads-preview{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  .status-yellow{
    padding: 0;
    margin: 0;
    pointer-events:none;
    cursor:none;
    transition:none;
    align-self: center;
    display: inline-flex;
  }

  .status-yellow--margin-right {
    margin-right: 8px;
  }

  .ads-review{
    vertical-align: inherit;
    background-color: #fcf8e3;
    margin-right: 0px;
    margin: 15px 0;

  }
  .ads-date{
    text-align: left;
    align-self: center;
    display: inline-flex;
    color:black;
    font-size:14px;
    font-weight: bold;
  }
  >div{
    flex: 1 0 50%;
    display: flex;
    align-self: center;
    &:nth-child(2){
      text-align:right;
    }
  }

  >div:last-child {
    flex: 0 1 100%;
    align-self: center;
  }
}
#modal-request-ad{

  .modal-content.flexDisplay{
    min-width: 764px;
    width: 100%;
  }
  label:empty{
    display: none!important;
  }
  #commentSection{
    >div{
      margin-bottom: 0px;
    }
  }


  $custom-radio-size:                 32px;
  $custom-radio-border-width:         1px;
  $custom-radio-transition-speed:     100ms;
  $custom-radio-inset-shadow:         inset 0 .1em 1px -.1em rgba(0,0,0,.3);

  $custom-radio-pip-color:            #00ce2d;
  $custom-radio-pip-size:             round($custom-radio-size * .6);

  // States
  $custom-radio-unchecked-bg:         white;
  $custom-radio-unchecked-border:     transparentize(#cdd1d4, .6);

  $custom-radio-checked-bg:           #d3ffdd;
  $custom-radio-checked-border:       #93e4a5;

  $custom-radio-active-inset-shadow:  inset 0 0 2px 3px rgba(0,0,0,.1);

  $custom-radio-focus-shadow:         0 0 0 2px transparentize(#d3ffdd, .5);

  $custom-radio-disabled-bg:          lighten(black, 91.8%);
  $custom-radio-disabled-pip-color:   lighten(black, 80%);


  // Check variable values
  @if ($custom-radio-size % 2 == 1) { @error '$custom-radio-size must be even'; }
  // round to nearest even number
  @if ($custom-radio-pip-size % 2 == 1) { $custom-radio-pip-size: $custom-radio-pip-size - 1; }


  input[type="radio"] {
    cursor:pointer;
    position: relative;
    display: inline-block;

    width:  $custom-radio-size - 2;
    height: $custom-radio-size - 2;

    border-radius: 100%;
    outline: none !important;
    -webkit-appearance: none;

    // Radio
    // -----
  + label {
    cursor:pointer;
  }
    &::before {
      position: relative;
      top:  -$custom-radio-border-width;
      left: -$custom-radio-border-width;
      display: block;
      content: '';


      background: $custom-radio-unchecked-bg;
      border: $custom-radio-border-width solid $custom-radio-unchecked-border;
      border-radius: 100%;
      box-shadow: $custom-radio-inset-shadow;

      width:  $custom-radio-size;
      height: $custom-radio-size;
    }

    &:active::before {
      box-shadow: $custom-radio-inset-shadow,
      $custom-radio-active-inset-shadow;
    }

    &:focus::before {
      box-shadow: $custom-radio-inset-shadow,
      $custom-radio-focus-shadow;
    }

    &:checked::before {
      background: $custom-radio-checked-bg;
      border-color: $custom-radio-checked-border;
    }

    &:disabled::before {
      cursor: not-allowed;
      background-color: $custom-radio-disabled-bg;
      border-color: transparentize($custom-radio-unchecked-border, .2);
    }



    // Radio Pip
    // ---

    &::after {
      position: relative;
      top: -($custom-radio-size / 2) - $custom-radio-border-width;
      left: ($custom-radio-size / 2) - $custom-radio-border-width;


      display: block;
      content: '';

      background: $custom-radio-pip-color;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0,0,0,.1);

      width:  0;
      height: 0;
    }

    &:checked::after {
      transition: all ease-in-out $custom-radio-transition-speed 0;

      top:  floor(
                      - ($custom-radio-size / 2)
            - ($custom-radio-pip-size / 2)
            - $custom-radio-border-width
            - .5
      );

      left: floor(
            + ($custom-radio-size / 2)
            - ($custom-radio-pip-size / 2)
            - $custom-radio-border-width
            + 1
    );

      width:  $custom-radio-pip-size;
      height: $custom-radio-pip-size;
    }

    &:disabled::after {
      background: $custom-radio-disabled-pip-color;
    }
  }
  
  input[type="radio"]::before {
    width: 20px!important;
    height: 20px!important;
  }
  input[type="radio"]:checked::after {
    width: 10px;
    height: 10px;
    top: -16px;
    left: 4px;
  }
  input[type="radio"]:focus::before {
    box-shadow: inset 0 0 0px 0em rgba(0, 0, 0, 0.3), 0 0 0 0px rgba(30, 144, 255, 0.5);
  }


}

#modal-send-emails {
  .btn-yellow-normal{
    margin-right: 1px;
  }
}
