.loginScreen {
    background-color: #333f48;
    background-image: url("../../images/svg/login-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    .red{
        color: #ff4844;
    }
    .w-h2,
    .w-p {
        display: block;
        vertical-align: top;
    }
    .w-h2{
        font-family: $cera-r;
        font-size: $size-xl;
        line-height: 1.42;
        text-align: center;
        color: #ffffff;
    }
    .w-p{
        font-family: $cera-r;
        font-size: $size-xl;
        line-height: 1.42;
        text-align: center;
        color: #a0abb2;
    }

    .contentHolder{
        margin: 0 auto;
        float: inherit;
        input{
            min-height: 50px;
            border-radius: 3px;
            background-image: linear-gradient(to top, #ffffff, #f7f7f7);
            border: solid 1px #ffffff;
            box-shadow: none;
            font-family: $cera-m;
            font-size: $size-l;
            font-weight: 500;
            text-align: left;
            color: #bbc1c6;

            &:hover{
                border-radius: 3px;
                background-image: linear-gradient(to top, #ffffff, #f7f7f7);
                border: solid 1px #ffd87d;
                box-shadow: none;
                @include placeholder-color(#848e95);
                color: #848e95;

            }
            &:focus,
            &:active{
                background-image: linear-gradient(to top, #fffefa, #fffcf3);
                border: solid 1px #ffd87d;
                box-shadow: none;
                @include placeholder-color(#131e29);
                color: #131e29;
            }
        }
        .form-group,
        .btn-yellow-normal {
            max-width: 418px;
            margin-left: auto;
            margin-right: auto;
            float: inherit;
        }
        .logoSupplier{
            margin-top: 21vh;
            margin-bottom: 43px;
            img{
                width: 230px;
            }
        }
        .w-p{
            margin-bottom: 56px;
        }
        .w-label{
            margin-bottom: 10px;
        }
        .form-group{
            margin-bottom: 28px;
        }
        #submit{
            width: 100%;
        }
    }

    .footerHolder{
        position: fixed;
        bottom: 0;
        .service{
            font-family: $cera-r;
            font-size: 16px;
            line-height: 2;
            text-align: center;
            color: #a0abb2;
            margin-bottom: 7px;
        }
        .list-inline-dot{
            display: inline-block;
            margin-bottom: 55px;
        }
        .list-inline-dot a {
            display: inline-block;
            float: left;
            margin-right: 10px;
            color: #ffffff;
            font-size: 16px;
            line-height: 2;
            &:last-child{
                margin-right: 0px;
            }
        }
        .logoHolder{
            margin-bottom: 15px;
        }
        .bulletDot:before {
            content: "\2022";
            font-size: 15px;
        }
    }
    .error-label.label.label-danger{
        display: block;
        background-color: #ff4a4a;
        font-family: $cera-m;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        text-align: left;
        color: #ffffff;
        max-width: 418px;
        margin: 0 auto;
        margin-top: 10px;

    }
    .error-label.label.label-danger{
        padding: 10px 15px;
        &:empty{
            padding: 0!important;
        }
    }
}

@media (max-height: 768px) {
    .loginScreen .contentHolder {
        .logoSupplier{
            margin-top: 55px;
        }
    }
    .loginScreen .footerHolder {
        position: relative;
        bottom: inherit;
        margin-top: 55px;
    }
    body:not(.mini-navbar) {
        background-color: #333f48!important;
    }
}