//Workero colors
//.................................
//Yellow
//Selected, Active, Buttons
$c-yellow: #F0B323;
$c-yellow-l: #FFC824;
//Dark Navy
//Text, Paragraph,  Titles, Stading-out Areas
$c-navy: #131e29;
$c-dark-navy: #21282D;
//Dark Gray
//Default Main Menu Icons
$c-gray-d: #7c878e;
//Medium Gray
//-
$c-gray: #333f48;
//Light Gray
//Fields Name, Tab Items, Field Text, Field Arrow
$c-gray-l: #A0ABB2;
//Gray
//-
$c-gray-b: #f2f2f2;
$c-gray-t: #D6DADC;
$c-gray-tag-b: #CDD1D4;
$c-gray-tag-t: #A0ABB2;
$c-gray-t-l: #BCC5CB;
$c-gray-menu-hover: #a1abb2;
//White
//-
$c-white: #ffffff;
//Red
//-
$c-red-t: #FF4A4A;
$c-red-t-l: #FF7F7F;


//Workero Font Size
//.................................
$size-s: 14px;
$size-l: 16px;
$size-l-xl: 18px;
$size-xl: 24px;

//Workero Typography

//Workero Font Face
//.................................
//Cera Regular
@font-face {
    font-family:'CeraGR-Regular';
    src: url('../../fonts/webfonts/35E4B4_9_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family:'CeraGR-RegularItalic';
    src: url('../../fonts/webfonts/35E4B4_8_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
//Cera Regular Medium
@font-face {
    font-family:'CeraGR-Medium';
    src: url('../../fonts/webfonts/35E4B4_6_0.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family:'CeraGR-MediumItalic';
    src: url('../../fonts/webfonts/35E4B4_7_0.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
//Cera Regular Bold
@font-face {
    font-family:'CeraGR-Bold';
    src: url('../../fonts/webfonts/35E4B4_5_0.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family:'CeraGR-BoldItalic';
    src: url('../../fonts/webfonts/35E4B4_1_0.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}
//Workero Fonts
//.................................
$cera-r: "CeraGR-Regular";
$cera-ri: "CeraGR-RegularItalic";
$cera-m: "CeraGR-Medium";
$cera-mi: "CeraGR-MediumItalic";
$cera-b: "CeraGR-Bold";
$cera-bi: "CeraGR-BoldItalic";


//Mixins
@mixin button-tag($color-o) {
    min-height: 32px;
    border-radius: 3px;
    border: 1px solid $color-o;
    padding: 10px;
    @include inline-block;
    user-select: none;
    vertical-align: top;
}
@mixin button-small($color-o) {
    min-height: 36px;
    border-radius: 3px;
    border: 2px solid $color-o;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
    @include inline-block;
    user-select: none;
    vertical-align: top;
}
@mixin button-big($color-o) {
    min-height: 46px;
    border-radius: 3px;
    border: 2px solid $color-o;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    @include inline-block;
    user-select: none;
    vertical-align: top;
}
@mixin button-color($color-b) {
    background-color: $color-b;
}
@mixin button-text($color-f) {
    font-family: $cera-b;
    text-transform: uppercase;
    color: $color-f;
    font-size: $size-s;
    line-height: 1;
}
@mixin button-round-small($color-b){
    background-color: $color-b;
    min-height: 40px;
}
@mixin button-round-big($color-b){
    background-color: $color-b;
    min-height: 50px;
}
@mixin round-button-text($color-t) {
    color: $color-t;
}

@mixin btn-yellow {
    @include button-color($c-yellow);
    @include button-big($c-yellow);
    @include button-text($c-white);
}
@mixin btn-yellow-small {
    @include button-color($c-yellow);
    @include button-small($c-yellow);
    @include button-text($c-white);
}
@mixin btn-white{
    @include button-color($c-white);
    @include button-big($c-yellow);
    @include button-text($c-yellow);
}
@mixin btn-white-small {
    @include button-color($c-white);
    @include button-small($c-yellow);
    @include button-text($c-yellow);
}
@mixin btn-yellow-hover {
    @include button-color($c-yellow-l);
    @include button-big($c-yellow-l);
    @include button-text($c-white);
}
@mixin btn-yellow-small-hover {
    @include button-color($c-yellow-l);
    @include button-small($c-yellow-l);
    @include button-text($c-white);
}
@mixin btn-white-hover{
    @include button-color($c-white);
    @include button-big($c-yellow-l);
    @include button-text($c-yellow-l);
}
@mixin btn-white-small-hover {
    @include button-color($c-white);
    @include button-small($c-yellow-l);
    @include button-text($c-yellow-l);
}
@mixin btn-yellow-disable {
    @include button-color($c-gray-b);
    @include button-big($c-gray-b);
    @include button-text($c-gray-t);
}
@mixin btn-yellow-small-disable{
    @include button-color($c-white);
    @include button-small($c-gray-b);
    @include button-text($c-gray-t);
}
@mixin btn-white-disable{
    @include button-color($c-white);
    @include button-big($c-gray-t);
    @include button-text($c-gray-t);
}
@mixin btn-white-small-disable {
    @include button-color($c-white);
    @include button-small($c-gray-t);
    @include button-text($c-gray-t);
}
@mixin btn-tag-normal{
    @include button-color($c-white);
    @include button-tag($c-gray-tag-b);
    @include button-text($c-gray-tag-t);
}
@mixin btn-tag-hover{
    @include button-color($c-white);
    @include button-tag($c-gray-tag-t);
    @include button-text($c-gray-tag-t);
}
@mixin btn-tag-disable{
    @include button-color($c-white);
    @include button-tag($c-gray-t);
    @include button-text($c-gray-t);
}

@mixin disable-cursor{
    cursor: default;
    user-select: none;
}
@mixin inline-block {
    margin-left: 0px;
    margin-right: -5px;
    display: inline-block;
}
@mixin status{
    font-size: $size-s;
    font-family: $cera-b;
    text-transform: uppercase;
    @include inline-block;
    vertical-align: top;
}
@mixin status-disable{
    color: $c-gray-t;
}
@mixin no-animation{
    margin-top: 0;
    margin-bottom: 0;
}
//INVERT
@mixin btn-yellow-invert {
    @include button-color($c-white);
    @include button-big($c-white);
    @include button-text($c-yellow);
}

@mixin btn-yellow-hover-invert {
    @include button-color(#fff3d7);
    @include button-big(#fff3d7);
    @include button-text($c-yellow);
}
@mixin btn-yellow-disable-invert {
    @include button-color(rgba(255, 255, 255, 0.3));
    @include button-big(rgba(255, 255, 255, 0));
    @include button-text($yellow);
}
@mixin btn-white-invert{
    @include button-color($c-yellow);
    @include button-big($c-white);
    @include button-text($c-white);
}
@mixin btn-white-hover-invert{
    @include button-color($c-yellow);
    @include button-big(#fff3d7);
    @include button-text(#fff3d7);
}
@mixin btn-white-disable-invert{
    @include button-color($c-yellow);
    @include button-big($c-white);
    @include button-text($c-white);
    opacity: 0.3;
}

@mixin placeholder-color($color) {
    &::-webkit-input-placeholder { /* WebKit browsers */
        color: $color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $color;
    }
}

//@mixin browser($browsers: Mozilla) {
//    @each $browser in $browsers {
//        html[data-browser*="#{$browser}"] & {
//            @content;
//        }
//    }
//}
