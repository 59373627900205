.booking-hero-profile{
    .header {
        padding: 50px 30px;
        .inlineBlock{
            vertical-align: baseline;
            > a{
                vertical-align: middle;
            }
        }
    }
    .detailProfile{
        .row {
            padding-bottom: 55px;
        }
        .heroInfo {
            img {
                margin-right: 15px;
                max-width: 120px;
            }
            .inlineBlock.info {
                vertical-align: top;
                margin-top: 20px;
            }
        }
    }
    .info {
        .description{
            font-family: $cera-r;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #131e29;
        }
    }
    .totalNumber{
        border-radius: 47px;
        background-color: #f0b323;
        color: #ffffff;
        padding: 0px 10px 2px 10px;
        text-transform: uppercase;
    }
    .infoBlock{
        display: block;
        width: 100%;
        .labelBlock{
            font-family: $cera-b;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #a0abb2;
            padding-bottom: 20px;

        }
        .companyImage{
            max-width: 65px;
            max-height: 65px;
        }
        img{
            margin-right: 30px;
        }
        .title{
            font-family: $cera-r;
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #272733;
        }
        .company,
        .period{
            font-family: $cera-r;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #a0abb2;
        }
        .description{
            font-family: $cera-r;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #131e29;
            padding-top: 15px;
        }
        .extraDetails{
            font-family: $cera-r;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #a0abb2;
        }
        .displayBlock {
            padding-bottom: 45px;
            > .inlineBlock{
                vertical-align: top;
            }
        }
    }
}
.grayBg.sidebar{
    background-color: #f2f2f2;
    min-height: 92vh;
    position: fixed;
}
.grayBg.sidebarProfile{
    background-color: #f2f2f2;
    min-height: 92vh;
    //position: fixed;

    .w-sectionLabel{
        font-family: $cera-r;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a0abb2;
    }
    .w-infoContent{
        font-family: $cera-r;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #272733;
        padding-bottom: 50px;
            text-transform: normal;
    }
}
.orangeBg{
    background-color: #ffd87a;
    padding: 30px 35px 30px 30px;
}
.status.active{
    color: #00ce2d;
}