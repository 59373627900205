// MENU MIXIN

@mixin menutext {
	font-family: $cera-bold;
	font-size: $s-xxs;
	text-align: center;
	color: $c-lightgray;
	background-color: $c-darkgray;
    width: 100%;
    display: inline-block;
	visibility: hidden;
	text-transform: uppercase;
	font-weight: 400;
}

@mixin menuicon{

	margin: auto;
	content: '';
	-webkit-mask-size: cover;
	mask-size: cover;
	// background-color: $c-lightgray;
    background-size: 32px 32px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px; 
    height: 32px;
    content:"";

}

@mixin officeOption {
	color: $c-darkblue;
	font-size: $s-l;
	font-family: $cera;
	line-height: 1.42;
	font-weight: normal;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}