.w-main-menu {
    width: 100px;
    height: calc(100vh - 80px);
    margin-top: 80px;
    background-color: $c-gray;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    .profileSubmenu{
        position: absolute;
        left: 60px;
        top: -10px;
        padding: 15px;
        box-shadow: none;
        border: 1px solid #7c878e;
        a{
            display: block;
            color: #7c878e;
            text-transform: uppercase;
            font-family: $cera-b;
            &:hover{
                color: $c-yellow;
            }
        }
    }
    .w-logo-holder {
        background-color: $c-white;
        padding-left: 25px;
        padding-right: 25px;;
        line-height: 80px;
        height: 80px;
        vertical-align: middle;
        .w-logo-img{
            width: 46px;
            height: 31px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../images/menu-elements/w-logo-image.svg");
        }
    }
    .w-menu-icon{
        text-align: center;
        .glyph{
            width: 100%!important;
            height: 100%!important;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        &.company{
            //margin-top: 40px;
            .glyph{
                background-image: url("../images/menu-items/company.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/company-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/company-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/company-disable.svg");
                }
            }
        }
        &.office{
            .glyph{
                background-image: url("../images/menu-items/office.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/office-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/office-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/office-disable.svg");
                }
            }
        }
        &.heroes{
            .glyph{
                background-image: url("../images/menu-items/heroes.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/heroes-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/heroes-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/heroes-disable.svg");
                }
            }
        }
        &.calendar{
            .glyph{
                background-image: url("../images/menu-items/calendar.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/calendar-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/calendar-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/calendar-disable.svg");
                }
            }
        }
        &.events{
            .glyph{
                background-image: url("../images/menu-items/events.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/events-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/events-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/events-disable.svg");
                }
            }
        }
        &.finance{
            .glyph{
                background-image: url("../images/menu-items/finance.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/finance-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/finance-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/finance-disable.svg");
                }
            }
        }
        &.ambassador{
            .glyph{
                background-image: url("../images/menu-items/ambassador.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/ambassador-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/ambassador-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/ambassador-disable.svg");
                }
            }
        }
        &.faq{
            .glyph{
                background-image: url("../images/menu-items/faq.svg");
            }
            &:hover {
                .glyph{
                    background-image: url("../images/menu-items/faq-hover.svg");
                }
            }
            &:active,
            &.active{
                .glyph{
                    background-image: url("../images/menu-items/faq-active.svg");
                }
            }
            &.disable{
                .glyph{
                    background-image: url("../images/menu-items/faq-disable.svg");
                }
            }
        }
        &.profile{
            .glyph{
               img{
                   max-width: 45px;
                   max-height: 45px;
               }
            }
        }

        .w-iconholder {
            width: 50px;
            height: 50px;
            padding: 9px;
            display: inline-block;;
           &.noPadding {
                padding: 0px!important;
                margin-bottom: 10px;
               div{
                   max-width: 100%!important;
                   max-height: 100%!important;
               }
            }
            div{

                max-width: 32px;
                max-height: 32px;
                height: auto;
                background-size: contain;
            }
        }
        span{
            width: 100%;
            font-family: $cera-b;
            font-size: 10px;
            text-align: center;
            color: $c-gray-d;
            text-transform: uppercase;
            display: inline-block;
            margin-bottom: 15px;
            top: 0px;
            line-height: 1;
            vertical-align: top;
            visibility: hidden;
        }

        &:hover,
        &:focus{
            span {
                visibility: visible;
                color: $c-gray-menu-hover;
            }
        }
        &:active,
        &:focus,
        &.active {
            span{
                visibility: visible;
                color: $c-yellow;
            }
        }
        &.disable{
            span{
                visibility: visible;
                color: $c-dark-navy;
            }

        }
    }
    .w-top-menu{
        height: calc(100vh - 300px);
        //padding-left: 25px;
        //padding-right: 25px;
        text-align: center;
        padding-top: 40px;
    }
    .w-bottom-menu{
        padding-left: 25px;
        padding-right: 25px;
        a{
            text-decoration: none;
            padding-bottom: 10px;
            &:last-child{
                padding-bottom: 0px!important;
            }
        }
    }
}

.profile.userImage{
    cursor: pointer;
    &:hover,
    &:focus{
        span {
            visibility: visible;
            border-color: $c-gray-menu-hover!important;
            color: $c-gray-menu-hover!important;
        }
    }
    &:active,
    &:focus,
    &.active {
        span{
            visibility: visible;
            border-color: $c-yellow!important;
            color: $c-yellow!important;
        }
    }
    &.disable{
        span{
            visibility: visible;
            color: $c-dark-navy;
        }

    }
}