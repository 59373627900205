.stepHeader + .contactInformation,
.stepHeader + .officeSpace,
.stepHeader + .stepContent,
.stepHeader + .houseRules{
    margin-top: -13px;
}
.stepHeader + .officeSpace{

}

.supplier ::-moz-placeholder{
    line-height: -moz-block-height;;
}

.supplier .office-box-container .office-box-bottom h3 {
    min-height: 30px;
}

.supplier {
    #new-products-holder {
        .delete-product {
            padding-left: 0px;
            padding-right: 30px;
        }
    }
    .product-container{
        .deskIcon{
            height: 60px!important;
            width: initial!important;
        }
        .deleteOption {
            margin-top: 0px;
        }
        .displayTable {
            div{
                margin-bottom: 5px;
                &.panel{
                    margin-bottom: 0px;
                }
                &.panel + a{
                    top: -5px;
                    position: relative;
                }
            }
        }

    }
    .editDraft {
        #add-another-product-edit-page + .selectComponent{
            &:after{
                width: inherit;
                right: 30px;
            }
        }
        h2 {
            margin-top: 55px;
            //margin-bottom: 20px;
        }
        hr{
            margin-top: 45px!important;
            margin-bottom: 45px!important;
        }
        .product-container.reset-selection .selectComponent::after {
            right: 35px;
        }
    }
    .drop-down .selectComponent::after,
    .stepContent .selectComponent::after {
        content: url(/fonts/drop-arrow-g.svg?56f7c79d328784b1bd637f17af1cbe6d);
        position: relative;
        right: 35px;
        margin-top: 0;
        z-index: inherit;
        width: inherit;
        margin-left: 0;
    }

    .editDraft{
        #new-products-holder .checkboxSlide,
        #anchor_for_products .checkboxSlide{
            display: inline-block;
            width: 100%;
            margin-bottom: 0px!important;
        }
    }

    .customGallery .countImages{
        width: 100%!important;
    }
    .customGallery .col-md-4.active-images {
        width: 100%;
        height: 100%;
        max-width: 268px;
        max-height: 186px;
        overflow: hidden;
        padding: 0px!important;
        background-color: #ffffff;
        min-height: inherit!important;
        margin-right: 15px!important;
        &:last-child{
            margin-right: 0px!important;
        }
        img{
            width: inherit;
            padding-top: 0px!important;
            padding: 0px;
            height: 100%;
        }
    }
    .col-md-4 .row .col-md-12 .active-images {
        img{
            //width: inherit;
            padding-top: 0px!important;
            //padding: 0px;
            //height: 100%;
        }
    }
    .col-md-8.img-responsive.active-images{

        background-color: #ffffff;
        img{
            padding-top: 0px!important;
            padding-bottom: 0px!important;
        }
        &+.col-md-4 {
            .active-images {
                img{
                    padding-top: 0px!important;
                    padding-bottom: 0px!important;
                }
            }
        }
    }

}


@-moz-document url-prefix() {
    .preview .collapse-button.collapsed::after {
        margin-top: -1px!important;
    }
    #event-rooms-preview-container{
        .deskIcon{
            content:'';
            background-image: url(../images/svg/evenroom.svg);
            background-size: contain;
            width: 52px!important;
            height: 60px!important;
            display: inline-block;
            background-repeat: no-repeat;
            margin-top: 30px;
        }
    }

    #meeting-rooms-preview-container {
        .deskIcon{
            content:'';
            background-image:  url(../images/svg/meetingroom.svg);
            background-size: contain;
            width: 52px!important;
            height: 60px!important;
            display: inline-block;
            background-repeat: no-repeat;
            margin-top: 30px;
        }
    }

    #private-offices-preview-container{
        .deskIcon{
            content:'';
            background-image: url(../images/svg/privateoffice.svg);
            background-size: contain;
            width: 52px!important;
            height: 60px!important;
            display: inline-block;
            background-repeat: no-repeat;
            margin-top: 30px;
        }
    }

    .supplier #desks-preview-container {
        .deskIcon{
            content:'';
            background-image: url(../images/svg/deskIcon.svg);
            background-size: contain;
            width: 52px!important;
            height: 60px!important;
            display: inline-block;
            background-repeat: no-repeat;
            margin-top: 30px;
        }
    }
}

.headerBody .btn-white-normal{
    background-color: #21282d;
    &:hover{
        background-color: #21282d;
    }
}

//STATUS FOR OFFICES
.infoFormat {
    &.review{
        color: #007fff!important;
    }
    &.live{
        color: #00ce2d!important;
    }
    .status_draft{
        color: #f0b323!important;
    }
    &.close{
        color: #ff4a4a!important;
    }
}

.plusIcon{
    content: none!important;
    background-image: url(../images/svg/plus-icon-y.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 11px!important;
    width: 11px!important;
    display: inline-block;
    margin-right: 5px;
}

.popover .popover-content {
    word-break: break-all;
}

#add-new-hero-manually-form .phoneHeroes .bs-searchbox{
    //margin-top: 80px;
}
#office-financial-container input,
#company-financial-container input {
    text-transform: capitalize;
}

.house-rule-file-container .progress-loading{
    margin-top: 13px;
}

.modal-content{
    border-radius: 5px;
}

.priceView input.form-control::-ms-expand {
    text-align: right;
    padding-right: 10px;
    font-family: $cera-r;
    text-transform: capitalize;

}

.priceView input.form-control::placeholder {
    text-align: right;
    padding-right: 10px;
    font-family: $cera-r;
    text-transform: capitalize;
}

.priceView input.form-control::-webkit-input-placeholder {
    text-align: right;
    padding-right: 10px;
    text-transform: capitalize;

}
.priceView input:-moz-placeholder {
    text-align: right;
    padding-right: 10px;
    font-family: $cera-r;
    text-transform: capitalize;

}
.priceView input.form-control::-moz-placeholder {
    text-align: right;
    padding-right: 10px;
    font-family: $cera-r;
    text-transform: capitalize;

}
.priceView input:-ms-input-placeholder {
    text-align: right;
    padding-right: 10px;
    font-family: $cera-r;
    text-transform: capitalize;
    //padding-right: 3px!important;
    //text-indent: -1px;
}


.supplier .houseFiles .inactiveOption .checkConfirmation {
    padding: 8px 0px 0px 0px !important;
    width: 10px;
    display: block;
}
.supplier .houseFiles .inactiveOption > span {
    float: right;
    padding: 0;
    margin-top: 12px;
    margin-right: 0px!important;
    width: 15px;
    content: '';
}
.supplier .house-rule-file-container .checkboxSlide label{
    padding-right: 55px!important;
}
#new-products-holder .selectComponent .selectArrow option {
    text-transform: capitalize;
}

//.create-product-selector{
//    display: flex;
//}

/* This is to remove the arrow of select element in IE */
.selectComponent select::-ms-expand {	display: none!important; }
.selectComponent select{
    -webkit-appearance: none;
    appearance: none;
}
//@-moz-document url-prefix(){
//    .selectComponent{border: 1px solid #CCC; border-radius: 4px; box-sizing: border-box; position: relative; overflow: hidden;}
//    .selectComponent select { width: 110%; background-position: right 30px center !important; border: none !important;}
//}

.rightTabContent .checkbox input + label::after {
    width: 10px!important;
    min-width: 10px!important;
    height: 10px!important;
}

    .supplier .stepContent .topSly .selectComponent::after {
        position: absolute;
        margin-right: 0px !important;
        right: 15px;
        display: inline-block;
        top: 13px;
        margin-top: 0;
        z-index: inherit;
        width: auto;
        margin-left: inherit;
    }

 input.form-control::-ms-expand,
 input.form-control::placeholder,
 input.form-control::-webkit-input-placeholder,
 input:-moz-placeholder,
 input.form-control::-moz-placeholder,
 input:-ms-input-placeholder {
    font-family: $cera-r;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #F0B323!important;
}
.daterangepicker .input-mini.active {
    border: 1px solid #F0B323!important;
    border-radius: 3px!important;
}

.supplier input.dateRange {
    width: calc(100% + 0px) !important;

}
.weekDays-selector{
    .daySelectorContainer {
        vertical-align: middle;
        line-height: 50px;
    }
    > h4{
        margin-bottom: 15px;
    }
    label{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    input + label {
        display: inline-block;
        width: 57.1px;
        height: 36.1px;
        border-radius: 3px;
        color: #333f48;
        line-height: 33px;
        text-align: center;
        cursor: pointer;
    }
}

span.info_label{
    font-family: $cera-r;
    font-size: 12px;
    text-align: left;
    color: #A0ABB2;
    //text-transform: uppercase;
    font-weight: 400;
    padding-top: 5px;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
}

.gallery-small-section{
    .image-gallery{
        max-width: calc(100% - 30px);
        right: -15px;
        position: relative;
        height: 290px;
        border-radius: 3px;
        overflow:hidden;
        background-color: transparent!important;

        .countImages{
            cursor: pointer;
            right:0px;
            height: calc(100% - 0px)!important;
            width: calc(100% - 15px);
            top:0px!important;
        }
    }
}

@media screen and (max-width: 991px){
    .gallery-small-section .image-gallery {
        max-width: calc(100% - 0px);
        right: 0;
        margin-top: 15px;
    }
}

.heroProfileEdit{
    .error-label.error-label-red{
        position: absolute;
    }
}

