// @font-face {
//   font-family: 'CeraGR-Regular';
//   src: url('fonts/CeraGR-Regular.eot');
//   src: url('fonts/CeraGR-Regular.woff2') format('woff2'),
//        url('fonts/CeraGR-Regular.woff') format('woff'),
//        url('fonts/CeraGR-Regular.ttf') format('truetype'),
//        url('fonts/CeraGR-Regular.svg#CeraGR-Regular') format('svg'),
//        url('fonts/CeraGR-Regular.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'CeraGR-RegularItalic';
//   src: url('fonts/CeraGR-RegularItalic.eot');
//   src: url('fonts/CeraGR-RegularItalic.woff2') format('woff2'),
//        url('fonts/CeraGR-RegularItalic.woff') format('woff'),
//        url('fonts/CeraGR-RegularItalic.ttf') format('truetype'),
//        url('fonts/CeraGR-RegularItalic.svg#CeraGR-RegularItalic') format('svg'),
//        url('fonts/CeraGR-RegularItalic.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'CeraGR-Bold';
//   src: url('fonts/CeraGR-Bold.eot');
//   src: url('fonts/CeraGR-Bold.woff2') format('woff2'),
//        url('fonts/CeraGR-Bold.woff') format('woff'),
//        url('fonts/CeraGR-Bold.ttf') format('truetype'),
//        url('fonts/CeraGR-Bold.svg#CeraGR-Bold') format('svg'),
//        url('fonts/CeraGR-Bold.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }
// 
// @font-face {
//     font-family:'CeraGR-Bold';
//     src: url('../fonts/ceraBold.eot');
//   src: url('../fonts/ceraBold.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/ceraBold.woff2') format('woff2'),
//     url('../fonts/ceraBold.woff') format('woff'),
//     url('../fonts/ceraBold.svg#R Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     unicode-range: U+0020-00F7;
// }
@font-face {
    font-family:'CeraGR-Bold';
    src: url('../webfonts/35E4B4_5_0.eot');
  src: url('../fonts/webfonts/35E4B4_5_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/webfonts/35E4B4_5_0.woff2') format('woff2'),
    url('../fonts/webfonts/35E4B4_5_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00F7;
}


@font-face {
    font-family:'CeraGR-Regular';
    src: url('../fonts/webfonts/35E4B4_9_0.eot');
    src: url('../fonts/webfonts/35E4B4_9_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/webfonts/35E4B4_9_0.woff2') format('woff2'),
    url('../fonts/webfonts/35E4B4_9_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00F7;
}



@font-face {
    font-family:'CeraGR-Medium';
    src: url('../fonts/webfonts/35E4B4_6_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family:'CeraGR-MediumItalic';
    src: url('../fonts/webfonts/35E4B4_7_0.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;  
}