.noPadding{
    padding: 0px!important;
}
.workeroBlock{
    .contentHolder{
        width: 100%;
        padding-left: 100px;
        padding-top: 80px;
        .mainHolder{
            background-color: $c-white;
        }
        .sidebarHolder{
            min-height: calc(100vh - 81px);
            height: 100%;
            background-color: #f2f2f2;
        }
    }
}