/*
 *
 *   Workero Admin Page
 *   Copyright 2017 expertnetwork.ro
 *
*/


/* GLOBAL STYLES
-------------------------------------------------- */
h3, h4, h5 {
    font-weight: 400;
}
body {
    font-family: $cera;
    font-size: $s-m;
}

.displayNone{
    visibility: hidden !important;
    display: none !important;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.supplier input.dateRange{
    width: 100%!important;
}
.glyph-home:after {
    @include menuicon;
    // -webkit-mask: url(../images/menu/home.svg) no-repeat 50% 50%;
    // mask: url(../images/menu/home.svg) no-repeat 50% 50%;    
    // @include menuicon;
    background-image: url('../images/menu/home.svg');
}

.glyph-company:after {
    // content: url(../images/menu/company.svg);
    @include menuicon;
    // -webkit-mask: url(../images/menu/company.svg) no-repeat 50% 50%;
    // mask: url(../images/menu/company.svg) no-repeat 50% 50%;  
    background-image: url('../images/menu/company.svg');
}

.glyph-offices:after {
    @include menuicon;
    // -webkit-mask: url(../images/menu/offices.svg) no-repeat 50% 50%;
    // mask: url(../images/menu/offices.svg) no-repeat 50% 50%; 

    background-image: url('../images/menu/offices.svg');

}

.glyph-heroes:after {
    @include menuicon;
    background-size: 24px 38px;
    width: 24px;
    height: 38px;
    background-image: url('../images/menu/heroes.svg');
}
.glyph-calendar:after {
    @include menuicon;
    background-image: url('../images/menu/calendar.svg');
}
.glyph-events:after {
    @include menuicon;
    background-image: url('../images/menu/events.svg');
}
.glyph-financial:after {
    @include menuicon;
    background-image: url('../images/menu/financial.svg');
}


.selectComponent select{
    cursor: pointer;
}


.supplier{
    background-color: $c-darkgray;
    .selectComponent > select{
        cursor: pointer;
    }
    .dropup
    {
        .dropdown-menu.open[role="combobox"]{
            border: none;
            box-shadow: none;
            background: transparent;
            .bs-searchbox{
                margin-top: 100px;
                border: solid 0.5px #ffd87d;
                box-shadow: 0 0 3px rgba(255, 216, 125, 0.7);
                background-color: $c-white;
                border-radius: 5px 5px 0 0;
            }
            .dropdown-menu.inner{
                background-color: $c-white;
                border: solid 0.5px #ffd87d;
                box-shadow: 0 0 3px rgba(255, 216, 125, 0.7);
            }
        }
    }
    //.contactInformation {
    //    .bs-searchbox{
    //        margin-top: 80px;
    //    }
    //}
    .contactInformation > div {
        padding-bottom: 50px;
    }
    input.dateRange {
        width: calc(100% - 15px) !important;
        text-indent: 15px;
    }
    .supplierNav {
        width: 100px;
        // background-color: $c-darkgray;
        .nav > li {
            width: 100%;
            padding-bottom: 25px;
            text-align:center;

            &:first-child{
                padding-top: 51px;
            }
            span{
                @include menutext;
            }
            &:hover {
                border-left: 0px solid $c-darkgray;
                background-color: $c-darkgray;
                a {
                    background-color: $c-darkgray;
                }
                span {
                    @include menutext;
                    color: $c-lightgray;
                    visibility: visible;
                }
            }
            &.active{
                border-left: 0px solid $c-darkgray;
                background-color: $c-darkgray;
                a {
                    background-color: $c-darkgray;
                }
                span {
                    @include menutext;
                    color: $c-yellow !important; 
                    visibility: visible;
                }

                .glyph-home:after{
                    background-image: url('../images/menu/home-y.svg');
                }
                .glyph-company:after{
                    background-image: url('../images/menu/company-y.svg');
                }
                .glyph-offices:after{
                    background-image: url('../images/menu/offices-y.svg');
                }
                .glyph-heroes:after {
                    background-image: url('../images/menu/heroes-y.svg');
                }
                .glyph-calendar:after {
                    @include menuicon;
                    background-image: url('../images/menu/calendar-y.svg');
                }
                .glyph-events:after {
                    @include menuicon;
                    background-image: url('../images/menu/events-y.svg');
                }
                .glyph-financial:after {
                    @include menuicon;
                    background-image: url('../images/menu/financial-y.svg');
                }


            }
            
            
            a{
                padding: 0 0 0 0;
                &:hover{
                    background-color: $c-darkgray;
                }

                i{
                    margin-right: auto;
                }

            }
        }        
    }


.pageWrapper {
    margin: 0 0 0 100px !important;
} 

.workero-topnav {
    height: 80px;
    padding: 15px;
    width: 100%;
    display: block;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
   
    .workero-logo {
        width: 70px;
        float: left;
        margin-right: 15px;
        height: 50px;
        display: inline-block;    
        img {
            width: 70px;
        }
    }
    .workero-search{
        width: calc(100% - 448px);
        height: 50px;
        border-radius: 3px;
        background-image: linear-gradient(to top, #ffffff, #f7f7f7);
        display: inline-block;

        .navbar-form-custom {
            width: 100%;
        }

        /* enable absolute positioning */
        .inner-addon { 
            position: relative; 
        }

        /* style icon */
        .inner-addon .glyphicon {
          position: absolute;
          pointer-events: none;
        }

        /* align icon */ 
        .left-addon.glyphicon  { left:  20px;}
        .right-addon.glyphicon { right: 20px;}

        /* add padding  */
        .left-addon input  { padding-left:  55px; }
        .right-addon input { padding-right: 55px; } 

        .glyph-search{
            margin: auto;
                content: '';
                -webkit-mask-size: cover;
                mask-size: cover;
                background-size: 22px 22px;
                background-repeat: no-repeat;
                display: inline-block;
                width: 22px; 
                height: 22px;
                content:"";
                background-image: url('../images/topnav/icon-search.svg');
                margin-top: 14px;
        }

        .glyph-arrow { 
                margin: auto;
                content: '';
                -webkit-mask-size: cover;
                mask-size: cover;
                background-size: 18px 16px;
                background-repeat: no-repeat;
                display: inline-block;
                width: 18px; 
                height: 16px;
                content:"";
                background-image: url('../images/topnav/arrow.svg');
                margin-top: 14px;
        }

        // input {
        //     &:after{
        //         margin: auto;
        //         content: '';
        //         -webkit-mask-size: cover;
        //         mask-size: cover;
        //         background-size: 22px 22px;
        //         background-repeat: no-repeat;
        //         display: inline-block;
        //         width: 22px; 
        //         height: 22px;
        //         content:"";
        //         background-image: url('../images/topnav/icon-search.svg');
        //     }
        //     &:before{
        //         margin: auto;
        //         content: '';
        //         -webkit-mask-size: cover;
        //         mask-size: cover;
        //         background-size: 18px 16px;
        //         background-repeat: no-repeat;
        //         display: inline-block;
        //         width: 18px; 
        //         height: 16px;
        //         content:"";
        //         background-image: url('../images/topnav/arrow.svg');
        //     }
        // }
    }
}     

.navbar-form-custom .form-control {
    height: 50px;
}

.select-country-code,
input, select,.date{
    height: 50px;
}
.select-country-code,
input, select,.date,textarea{
  border-radius: 3px;
  background-image: linear-gradient(to top, #ffffff, #f7f7f7);
  border: solid 1px #cdd1d4;
  &:focus {
    background-image: linear-gradient(to top, #fffefa, #fffcf3);
    border: solid 1px #ffd87d;
  }
    &:hover {
        border: solid 1px #ffd87d;
    }
}
    .error-input{
        background-color: #fff8f8!important;
        border: solid 1px #ff4a4a!important;
    }
.select-country-code,
input, select,.date,textarea{
    &.validInput {
        border: solid 1px #93e4a5;
    }
  
}



.stepHeader{
    min-height: 133.4px;
    background-color: #f0b323; 
}
.titleStep {    
    display: inline-block;
    font-family: $cera;
    font-size: $s-l;
    font-weight: 500;
}
.firstStep .lineHeader  {
        padding-left: 15px;
    }

.lineHeader {
    padding-right: 15px;
    padding-left: 50px;
   

    h4 {
        margin-top: 30px;
        color: $c-white;
        text-transform: uppercase;

    }
    h2 {
        margin-top: 0px;
        color: $c-darkergray;
        .stepBack{
            content: url(../images/svg/back-arrow-g.svg);
            position: absolute;
            margin-left: -50px;
            margin-top: 3px;
        }


                .stepBack{
                    background-image: url('../images/svg/back-arrow-g.svg');
                    background-size: 22px 19px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    width: 22px; 
                    height: 19px;
                    content:"";
                    margin-left: -35px;
                    margin-top: 5px;
                }
    }
    .stepNavigation {
        display: inline-block;
        float: right;
    }
    .buttonHeader {
        height: 50px;
        border-radius: 3px;
        color: $c-white;
        text-transform: uppercase;
        padding: 15px 20px;
        font-size: 14px;
        text-decoration: none;
        &:hover {
            background-color: rgba(255, 255, 255, 1) !important;
            color: $c-yellow;

        }
        &.active {
            background-color: rgba(255, 255, 255, 1);
            color: $c-yellow;
            border: 1px solid $c-white;
            &.btnBorder{
                background-color: rgba(255, 255, 255, 0);
                border:1px solid $c-white;
                color: $c-white;
                margin-right: 10px;
                &:hover{
                    color: $c-yellow;
                }
            }
            &.btnOpacity{
                background-color: rgba(255, 255, 255, 0.4);
                border:1px solid rgba(255, 255, 255, 0.1);
            }

        }
    }
}
.daySelectorContainer{
    display: block;
    min-height: 50px;
}
    .weekDays-selector input {
      display: none!important;
    }

    .weekDays-selector input + label {
      display: inline-block;
      width: 57.1px;
      height: 38.1px;
      border-radius: 3px;
      color: $c-darkgray;
      line-height: 38px;
      text-align: center;
      cursor: pointer;
    }
    .weekDays-selector input[value="1"] + label.label-day-selector
    // ,
    // .weekDays-selector input.activeDay + label 
    {
      background-color: $c-yellow;
      color: #ffffff;
    }

    select {
        appearance:none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
    }

    .basicButton{
        background-color: transparent;
        border: 0px solid;
        vertical-align: middle;
        line-height: 49px;
        display: inline-block;
        float: left;
        font-size: $s-xs;
        font-family: $cera-bold;
    }

    .yellow{
        color: $c-yellow;
    }
    .red {
        color: $c-red;
    }
    // .form-group {
    //     margin-right: 20px;
    // }
    .form-group > select {
        min-width: 195px;
    }

    .form-group.weekDays-selector,
    .form-group.datePicker,
    .closing-interval{
        //min-width: 35%;
        select {width:100%;}
    // }
    // {
        display: inline-table;
        
    }

    .tipsTricks{
        background-color: #f2f2f2;
        height: 100%;
    }
    .flexDisplay{
        display: flex;
    }

    .flexDisplay > div.col-lg-9{
        flex: 3;
    }
    .flexDisplay > div.col-lg-3{
        flex: 1;
        
    }
    
    .select-country-code{
        margin-right: -2px !important;
     }
    .select-country-code > span:before{
        content: url(../images/svg/drop-arrow-g.svg);
        margin-left: 15px;
    }
    .selectComponent:after{
        content: url(../images/svg/drop-arrow-g.svg);
        //position: absolute;
        //margin-left: 168px;
        //margin-top: -37px;
        //z-index: 5;
        position: relative;
        //right: 35px;
        margin-top: 0;
        z-index: inherit;
        width: initial;
        margin-left: 0;
        position: absolute;
        margin-top: -37px;
        z-index: 200;
        pointer-events: none;
    }

    /* CUSTOM CHECKBOX */
    .checkbox label:after, 
    .radio label:after {
        content: '';
        display: table;
        clear: both;
    }

    .checkbox .cr,
    .radio .cr {
        position: relative;
        display: inline-block;
        border: 1px solid #a9a9a9;
        border-radius: .25em;
        width: 1.3em;
        height: 1.3em;
        float: left;
        margin-right: .5em;
    }

    .radio .cr {
        border-radius: 50%;
    }

    .checkbox .cr .cr-icon,
    .radio .cr .cr-icon {
        position: absolute;
        font-size: .8em;
        line-height: 0;
        top: 50%;
        left: 20%;
    }

    .radio .cr .cr-icon {
        margin-left: 0.04em;
    }

    .checkbox label input[type="checkbox"],
    .radio label input[type="radio"] {
        display: none;
    }

    .checkbox label input[type="checkbox"] + .cr > .cr-icon,
    .radio label input[type="radio"] + .cr > .cr-icon {
        transform: scale(3) rotateZ(-20deg);
        opacity: 0;
        transition: all .3s ease-in;
    }

    .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
    .radio label input[type="radio"]:checked + .cr > .cr-icon {
        transform: scale(1) rotateZ(0deg);
        opacity: 1;
    }

    .checkbox label input[type="checkbox"]:disabled + .cr,
    .radio label input[type="radio"]:disabled + .cr {
        opacity: .5;
    }    /* END CUSTOM CHECKBOX */

    .stepHeader {
        margin-bottom: 50px;
    }
    .tipsTricks{
        padding:50px 30px;
        ul{
            -webkit-padding-start: 0px;
            list-style: none;
             padding: 0;
    list-style-type: none;

            li{
                padding-bottom:25px;
                span{
                    padding-left: 0px;
                    display: flex;
                }
                // :before {
                //     content: url(../images/svg/check-icon-g.svg);
                //     height: 15px;
                //     width: auto; 
                //     margin-right: 5px;
                // }
                // 
                span:first-child{
                    display: inline-block;
                    float: left;
                    &:before {
                    background-image: url('../images/svg/tipsCheck.svg');
                    background-size: 20px 13px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    width: 20px; 
                    height: 13px;
                    content:"";
                    margin-right: 10px;
                }
                }
                
            }
        }
    }
    .tipsTricks h4,
    .stepContent h4{
        color: $c-lightgray;
    }
    .tipsTricks h4,
    .stepContent h4,
    .basicButton{
        font-family: $cera-bold;
        font-size: $s-xs;
    }
    .form-control,
    option, .input-group-addon{
        color: $c-darkblue;
        font-size: $s-m;

    }
    .gray{
        color: $c-lightgray;
    }
    .dark{
        color: $c-darkblue;
    }
    .hideElement{
        visibility: hidden;
    }
    .deleteOption {
            margin-top: 27px;
    }
    .plusIcon{
        content: url(../images/svg/plus-icon-y.svg);
            height: 11px;
            width: auto; 
            margin-right: 5px;
    }

    .date {
        width: 100%;
    }
    .input-group-addon {
        text-align: left;
        text-indent: 15px;
        margin-bottom:-5px;
    }

    .input-group-addon:BEFORE{
        content: url(../images/svg/calendar-icon-y.svg);
            // height: 11px;
            width: 10px; 
            padding-right: 10px;
    }

    .officeOption {
        @include officeOption;
    }
    .houseRules .checkboxSlide{
        margin-right: initial;
    }
.checkboxSlide {
        margin-right: inherit;
        .form-group {
            margin-right: inherit;
        }
        .form-group select {
            min-width: 100%;
        }
        .selectComponent:after {
            margin-left: 80%;
            display: block;
        }
        .selectComponent select{
            width: 100%;
        }
        input {
            display: none;
        }
        .priceView input{
            display: block;
            width: 100% !important;
            text-align: right;

        }
        .currencyLabel {
            display: inline-block;
            position: absolute;
            margin-top: -5px;
            padding-left: 15px;
            font-family: $cera;
            font-size: $s-m;
            font-weight: 500;
            line-height: 1.56;
            color: $c-lightgray;
        }

        label {
            @include officeOption;
            cursor: pointer;
            padding-right: 35px;
            position: relative;
            display: block;
            padding: 15px 0;
            padding-right: 80px;
            text-transform: capitalize;
          }

        input[type="checkbox"], input[type="radio"] {
            position: absolute; 
            visibility: hidden !important;
        }

        input[type="checkbox"] + label, input[type="radio"] + label {

            &:before,
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              margin-top: -7.5px;
              box-sizing: border-box;
            }

            &:before {

                width: 80px;
                height: 30px;
                border-radius: 100px;
                background-color: #f2f2f2;
              right: 0px;
              // margin-top: -17px;
                margin-top: 17px;
                top: 0;
              
            }

            &:after {
              width: 40px;
            height: 40px;
            right: 40px;
            background: #7c878e;
            border-radius: 50%;
            transition: all 200ms ease-out;
            // margin-top: -22px;
            margin-top: 13px;
            top: 0;
            }
            + div button,
            + div input,
            + div select{
                background-color: #f2f2f2;
                background-image: none;
                border: solid 1px #f2f2f2;
                color: #d6dadc;
            }

            + div label+input {
                color: #d6dadc;
            }

            + div {
                .selectComponent:after {
                    content: none;
                }
                .currencyLabel,
                .selectComponent option{
                    color: #d6dadc;
                }
            }
             + div input{
                border-radius: 0 3px 3px 0;
                border-right:0px solid #fff !important;
            }
            + div .col-md-8 input{
                border-radius: 3px 0 0 3px;
                border-right:0px solid #fff !important;
                text-transform: capitalize;

            }
            + div .col-md-4 input{
                border-radius: 0 3px 3px 0;
                &[disabled="disabled"]{

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #d6dadc!important;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: #d6dadc!important;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: #d6dadc!important;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: #d6dadc!important;
                    }
                }

                //border-right:0px solid #fff !important;
            }
            + div button,
            + div select{
                border-radius: 3px 0 0 3px;
                 border-right:1px solid #fff !important;

            }
            
          }

        // input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
        //     &:before {
        //       background: #d3ffdd;
        //     }
        //     &:after {
        //       right: 0px;
        //       background: #00ce2d;
        //     }

        //     + div input,
        //     + div select{
        //         background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
        //         border: solid 1px #93e4a5;
        //         background-color: inherit;
        //         color: $c-darkblue;
        //     }

        //     + div {
        //         .selectComponent:after {
        //             content: url(../images/svg/drop-arrow-g.svg);

        //         }
        //         .oneOption.selectComponent:after {
        //                 content: none;
        //             }
        //         .currencyLabel,
        //         .selectComponent option{
        //             color: $c-darkblue;
        //         }
        //     }
        //          + div input{
        //         border-radius: 0 3px 3px 0;
        //         border-left: 1px solid #93e4a5 !important;
        //     }
        //     + div select{
        //         border-radius: 3px 0 0 3px;
        //          border-right: 1px solid #f2f2f2 !important;
        //     }
        // }
        input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
            &:before {
              background: #d3ffdd;
            }
            &:after {
              right: 0px;
              background: #00ce2d;
            }
            // + div {
            //     background-color: $c-green;
            // }
            + div button,
            + div input,
            + div select{
                background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
                border: solid 1px #93e4a5;
                background-color: inherit;
                color: $c-darkblue;
            }
            + div label+input {
               color: $c-darkblue;
            }
            + div {
                .selectComponent:after {
                    content: url(../images/svg/drop-arrow-g.svg);

                }
                .oneOption.selectComponent:after {
                        content: none;
                    }
                .currencyLabel,
                .selectComponent option{
                    color: $c-darkblue;
                }
            }
                 + div input{
                border-radius: 0 3px 3px 0;
                border-left: 1px solid #93e4a5 !important;
            }
            + div .col-md-8 input{
                border-radius: 3px 0 0 3px;
                border: 1px solid #cdd1d4;
                border-right: 0px solid #93e4a5 !important;
                border-left: 1px solid #cdd1d4!important;
            }
            + div .col-md-4 input{
                border-radius: 0 3px 3px 0;
                border-right: 1px solid #93e4a5 !important;
                border: 1px solid #93e4a5 !important;
            }
            + div button,
            + div select{
                border-radius: 3px 0 0 3px;
                 border-right: 1px solid #f2f2f2 !important;
            }
        }

    }

    .package {
        padding-top: 50px;
        width: 100%;
        display: inline-block;

            .checkboxSlide > div > label {
            font-size: $s-xl;
            margin-bottom: 0px;
            padding-bottom: 0px;
            &.packageDescription{
                  font-family: $cera;
                  font-size: 18px;
                  line-height: 1.89;
                  text-align: left;
                  color: #a0abb2;
                  padding-right: 15px;
                  margin-bottom: 0px;
                  padding-bottom: 30px;
                  margin-top: 0px;
                  padding-top: 0px;
            }
        }
    }

.gray-bg.white-bg{
    background-color: #ffffff;
}
label{
    font-family: $cera-bold;
    font-size: $s-xs;
    text-align: left;
    color: $c-lightgray;
    text-transform: uppercase;
    font-weight: 300;
}


@include placeholder {
   font-family: $cera-italic;
  font-size: $s-s;
  font-style: italic;
  line-height: 1.5;
  text-align: left;
  color: $c-gray;
}

.select-country-code{
    font-family: $cera-italic;
  font-size: $s-s;
  font-style: italic;
  line-height: 1.5;
  text-align: left;
  color: $c-gray;
  min-width: 100px;
}

textarea {
    max-height: 150px;
    resize: none;
}
.contactInformation textarea {
    margin-bottom: 50px;
}

.houseRules .basicButton.addOption{
    padding: 8px 15px;
    text-transform: uppercase;
}
.openOfficeSetup .addOption{
    margin-top: 50px;
}
.addOption{
    text-transform: uppercase;
}


.formControl{
        font-family: $cera-bold;
          font-size: 14px;
          text-align: left;
          color: #a0abb2;
          text-transform: uppercase;
          margin-top: 15px;
          display: inline-block;
          
          .btnCancel {
            color: #a0abb2;
            border: 1px solid #fff;
            padding: 15px;
            display: inline-block;
          }
          .btnSave {
            color: #f0b323;
            border: 1px solid #f0b323;
            font-size: $s-xs;
            line-height: $s-xs;
            padding: 17px 29px;
            background-color: transparent;
            text-transform: uppercase;
            border-radius: 3px;
          }
    
}

.checkConfirmation{
    padding: 16px 15px;
    display: inline-block;
    &:before {
                content: url(../images/svg/check-icon-g.svg);
            height: 15px;
            width: auto; 
            margin-right: 5px;
            }
}
.fileOptions {
    font-size: 14px;
    font-family: $cera-bold;
    margin-top: -17px;
    padding-left: 1px;
    a{  
        color: #f0b323;
        &.remove {
           color: #ff4a4a;
        }
    }
}
.marginBottom {
    margin-bottom: 30px;
}
.officeSpace .selectComponent:after{
        content: url(../images/svg/drop-arrow-g.svg);
        position: absolute;
        margin-left: calc(100% - 60px);
        margin-top: -37px;
        z-index: 5;
        pointer-events: none;
    }
    // OFFICE GRID
    // 
    .sectionHeader {
        font-family: $cera;
        font-weight: 500;
        font-size: $s-l;
        color: $c-darkblue;
    }
    .suitcase {
        content: url(../images/svg/suitcase.svg);
        height: 32px;
        width: 32px; 
        margin-bottom: -6px;
        margin-right: 10px;
    }
    .filterHeader {
        font-family: $cera-bold;
        font-weight: 500;
        font-size: $s-xs;
        color: $c-darkblue;
    }
    .angleDown{
        content: url(../fonts/drop-arrow-g.svg);
        width: 12.1px;
        height: 6px;
    }
    .gridIcon{
        content: url(../images/svg/gridIcon.svg);
        height: 16px;
        width: 17px; 
        margin-bottom: -3px;
    }

    .gmapsIcon{
        content: url(../images/svg/gmaps.svg);
        height: 16px;
        width: 16px; 
        margin-bottom: -4px;
    }

    .addNewBig {
        height: 50px;
        width: 50px; 
        border-radius: 50px;
        background-color: $c-yellow;
        display: inline-block;
        line-height: 44px;
        text-align: center;
        margin-bottom: 9px;
        // .bigPlus{
        //     content: url(../images/svg/bigPlus.svg);
        //     height: 26px;
        //     width: 26px; 
        //     vertical-align: middle;
        // }
    }

    .office-box-container{
         min-height: 135px;
    }
    .office-box-bottom {
        background-color: #fafafa;
        min-height: 166px;

        h3{
            font-family: $cera;
        font-size: $s-l;
        color: $c-darkblue;
        }
        
    }
    .labelFormat{
        font-family: $cera-bold;
        font-size: $s-xs;
        color: $c-lightgray;
        font-weight: 300;
        text-transform: uppercase;
    }
    .infoFormat{
        font-family: $cera;
        font-size: $s-xs;
        color: $c-darkblue;
        // text-transform: uppercase;
        padding-bottom: 5px; 
     }
    .fa-star,
    .fa-star-o {
        color: $c-yellow;
        font-size: $s-xs;
    }
    .file {
      visibility: hidden;
      position: absolute;
    }

    .datePicker{
        .dateRange{
            text-indent: 25px;
        }
        &.closingDate{
            input {
                cursor: pointer;
            }
            .deleteOption {
                margin-top: 0;
            }
        }
        &:before{

        content: url(../images/svg/calendar-icon-y.svg);
            height: 40px;
            width: 40px; 
            display: inline-block;
            position: absolute;
            margin-top: 15px;
            margin-left: 10px;
        }
    }
    .delete-closed-interval {
            margin-top: 0!important;

    }
    .bigTitle {
        font-family: $cera;
        font-size: $s-xl;
        color: $c-darkblue;
    }
    .displayTable {
        display: block;
        position: relative;
        > div{
            display: block;
            position: relative;
            width: 100%;
            float: left;
            font-family: $cera;
            font-size: $s-xs;
            color: $c-darkblue; 
            text-align: right;
            strong{
                font-family: $cera-bold;
                font-weight: 300;
                font-size: $s-xs;
                color: $c-lightgray; 
                text-transform: uppercase;
                float: left;
                line-height: 1;
            }
        }        
    }
    // .deskIcon{
    //     content: url(../images/svg/deskIcon.svg);
    //     width: 105%; 
    //     height: auto;
    //     margin-top: 40%;
    // }
    input[type=radio] {
        height: inherit;
    }
    .label-danger,
    .label-warning {
        color: $c-white;
    }
}


// correction design

.supplier{
    .stepHeader{
        margin-bottom: 50px;

        h4 {
            font-size: $s-xs;
            line-height: $s-xs;
            font-family: $cera-bold;
            font-weight: 400;
            text-align: left;
            text-transform: uppercase;
            color: $c-white;
        }
        h2 {
            line-height: $s-l;
            font-family: $cera-bold;
            font-size: $s-l;
            font-weight: 200;
            text-align: left;
            color: $c-darkblue;
            span {
                font-family: $cera;
                font-weight: 300;
            }
        }
        .stepNavigation {
            a {
                font-family: $cera-bold;
                font-size: $s-xs;
                font-weight: 300;
            }
        }
    }
    label {
        line-height: $s-xs;
        font-size:  $s-xs;
        margin-bottom: 12px;
        margin-top: 13px;
    }    
    .map-office-form {
        margin: 15px 0;
    }

    .contactInformation textarea {
        margin-bottom: 28px;
    }

    .tipsTricks{
        padding: 55px 30px;
        h4 {
            font-weight: 400;
            margin-bottom: 25px;
        }
    }
    .charLeft {
        text-transform: inherit;
        font-family: $cera;
        font-weight: $s-xs;
        color: $c-gray;
        font-style: italic;
    }
    .bellIcon {
        @include menuicon;
        background-image: url('../images/svg/notifications.svg');
        background-size: 25px 31px;
        width: 25px;
        height: 31px;
        margin-top: 3px;
    }
    .topMenu{
            top: 0;
    /* margin-top: 0px; */
    display: inline-block;
    position: absolute;
    right: 0;
    }
    .addNewBig {
        .bigPlus{
        @include menuicon;
        background-image: url('../images/svg/iconPlus.svg');
        background-size: 25px 31px;
        width: 26px;
        height: 27px;
        margin-top: 10px;
    }
    }



}

.clightgray{
    color: $c-vlightgray;
}
.cdarkblue{
    color: $c-darkblue;
}
.sl{
    font-size: $s-l;
}

.left-modal {
    width: 50px;
    background-color: $c-darkgray;
    display: inline-block;
    float: left;
    border-radius: 4px 0 0 4px;
}
.right-modal {
    display: inline-block;
    width: calc(100% - 50px) !important;
}

.iconHolder{
    width: 100%;
    height: calc(100% - 0px);
    text-align: center;
}
.route-icon-office,
.officeIcon{
    @include menuicon;
    background-image: url('../images/menu/offices-y.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
}
.route-icon-company,
.companyIcon{
    @include menuicon;
    background-image: url('../fonts/company-active.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
}

.route-icon-calendar,
.calendarIcon{
    @include menuicon;
    background-image: url('../fonts/calendar-active.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
}

.route-icon-ambassador,
.ambassadorIcon{
    @include menuicon;
    background-image: url('../fonts/ambassador-active.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
}

.controlMarginTop {
    height: calc(100% - 39px);
    width: 100%;
    display: block;
}
.modal-footer a.btnFill {
    background-color: $c-yellow !important; 
    // margin-bottom: 15px;
    display: inline-block;
    color: $c-white !important;
}

.modal-footer.modalFooter {
    border-top: 0px solid #e5e5e5;
    margin-bottom: 15px;
    margin-right: 15px;
    display: block;
    > .form-group {
        margin-bottom: 0;
    }
}
.modal-header.modalHeader {
    border-bottom: 0px solid #e5e5e5;
    padding: 30px 25px 0 30px;
    .close{
        font-size: 35px;
    }
}
#calendar-popup{
    button.close{
        margin-top: -10px;
    }
    h4.modalTitle{
        margin-top: 0;
    }
}

.right-modal h4.modalTitle {
    font-family: $cera;
    font-size: $s-l;
    text-align: left;
    color: $c-lightblue;
    font-weight: normal;
}
.right-modal .selectComponent select{
    width: 100%;
}
.right-modal .selectComponent:after {
    // margin-left: -25px;
    // margin-top: 12px;
    margin-left: calc( 100% - 65px ) !important;
    margin-top: -38px;
    pointer-events: none;
}

.modalDays{
     label {
        font-size: $s-l;
        font-family: $cera;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .daySelectorContainer{
        min-height: inherit;
    }
}
.marginT30{
    margin-top: 30px;
    padding-top:0px;
}
.currencyLabel {
    padding: 0 !important;
    margin-top: 10px !important;
    margin-left: 15px !important;
    pointer-events: none !important;
}
.repeatClosedDays {
        margin-top: 20px;
    margin-bottom: 5px;
.yellow{
    font-family: $cera-bold;
  font-size: $s-xs;
  font-weight: 400;
  text-align: left;
  color: $c-yellow;
  margin-top: 5px;
  padding-left: 10px;
  position: absolute;
}

}

.weekNo > span {
    font-family: $cera;
    font-weight: 400;
    font-size: $s-m;
    line-height: 1.56;
    text-align: left;
    color: $c-lightgray;
}
.weekNoFix {
    padding-top: 60px;
}
input[disabled="disabled"] {

                background-color: #f2f2f2;
                background-image: none;
                border: solid 1px #f2f2f2;
                color: #d6dadc ;

}
.currencyLabel + input[disabled="disabled"],
input[disabled="disabled"] + .currencyLabel{
     color: #d6dadc !important;
}

 button, div, a, span{
    &.progress-loading {
        font-family: $cera !important;
        font-weight: 400 !important;
        font-size: $s-l !important;
        line-height: 1.42 !important;
        text-align: right;
        color: $c-lightgray;
    }
}
.progress {
    font-family: $cera !important;
        font-weight: 400 !important;
        font-size: $s-l !important;
        line-height: 1.42 !important;
        text-align: right;
        color: $c-lightgray;
        background-color: $c-white;
}
.house-rule-file-container .checkConfirmation{
    padding: 22px 0px 0px 0px!important;
}
.house-rule-file-container > div > .upload-a-new-houserule-file button.basicButton.addOption {
    padding: 10px 0px 0px 0px!important;
}
.house-rule-file-container > div > .upload-a-new-houserule-file button.basicButton.addOption.progress-loading {
    padding: 23px 0px 0px 0px!important;
}
.browse.basicButton.addOption {
    padding-top: 0px !important;
}



    #event-rooms-preview-container{
        .deskIcon{
        content: url(../images/svg/evenroom.svg);
        width: 105%;
        height: auto;
        margin-top: 40%;
    }
    }

    #meeting-rooms-preview-container {
        .deskIcon{
        content: url(../images/svg/meetingroom.svg);
        width: 105%;
        height: auto;
        margin-top: 40%;
    }
    }

    #private-offices-preview-container{
        .deskIcon{
        content: url(../images/svg/privateoffice.svg);
        width: 105%;
        height: auto;
        margin-top: 40%;
    }
    }

    #desks-preview-container {
        .deskIcon{
        content: url(../images/svg/deskIcon.svg);
        width: 105%;
        height: auto;
        margin-top: 40%;
    }
    }

.checkboxSlide {
    margin-bottom: 15px !important;
    label {
        margin-top: 0px;
        h4 {
            margin-bottom: 0px;
        }
    }
}

label.houseRuleFSont {
    margin-top: 0px !important;
}
// CUSTOM DROPDOWN
    ul.zg-ul-select {
        height: 50px;
        border-radius: 3px;
        background-image: linear-gradient(to top, #ffffff, #f7f7f7);
        border: solid 1px #93e4a5;
        box-sizing: border-box;
        cursor: pointer;
        font-family: $cera;
        font-size: $s-l;
        margin: 0;
        padding: 0;
          li {
            border-radius: 3px;
            height: 48px;
            display: none;
            padding: 0.5em;
            &.active {
              align-items: center;
              border: 1px solid #111;
              border-radius: 3px;
              box-shadow: 0 0 2px #aaa;
              color: #111;
              display: flex;
              justify-content: space-between;
            }
          }
          &.active {
            border: 1px solid #aaa;
            li {
              border: none;
              box-shadow: none;
              display: block;
              &:hover {
                background: $blue;
              }
              &.active {
                background: lighten($blue, 20%);
                &:hover {
                  background: $blue;
                }
              }
            }
          }
        }

        #selected--zg-ul-select {
          align-items: center;
          box-sizing: border-box;
          color: $c-darkblue;
          display: flex;
          font-family: $cera;
          justify-content: space-between;
          font-size: $s-l;
          width: auto;
          &.active {
            border: 1px solid #93e4a5;
            border-radius: 3px;
            background-color: #ffffff;
            // box-shadow: 0 0 2px #aaa;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
            padding: 0.5em;
          }
        }

// CUSTOM SCROLLBAR
.container { margin: 0 auto; }

/* Example wrapper */
.wrap {
    position: relative;
    float: left;
    margin: 0 40px 3em 0;
    position: absolute;
    z-index: 10;
}
.wrap:nth-child(2n) { margin-right: 0; }

/* Frame */
.button-sly-select {
    border-radius: 0 3px 3px 0;
    // border: 1px solid #93e4a5 !important;
    height: 50px;
    text-align: left;
}
.frame {
    // width: 510px;
    height: 162px;
    // max-height: 162px;
    overflow: hidden;
    // border: 1px solid #444;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #93e4a5;
  background-color:#fff;
}
.frame .slidee {
    padding: 20px 25px;
    background: #fff;
}
.frame ul.items {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 24px;
    line-height: 50px;

}
.frame ul.items li {
    height: 50px;
  border-radius: 3px;
  background-color: #ffffff;

    float: left;
    width: 100%;
    margin: 0 0 1px;
    padding: 0;
    color: $c-darkblue;
    font-size: $s-m;
    text-indent: 15px;
    font-family: $cera;
    // text-align: center;
    cursor: pointer;
    border-bottom: solid 1px #cdd1d4;
}
.frame ul.items li.active {
    color: #131E29;
    background: #f2f2f2;
}

/* Scrollbar */
.scrollbar {
    position: absolute;
    right: 10px;
    width: 3px;
    height: 155px;
    // background: #ccc;
    line-height: 0;
    z-index: 20;
    background:#fff;
}
.scrollbar .handle {
    width: 100%;
    height: 100px;
    background: #cdd1d4;
    cursor: pointer;
}
.scrollbar .handle .mousearea {
    position: absolute;
    top: 0;
    left: -10px;
    width: 22px;
    height: 100%;
}

/* Pages */
.pages {
    list-style: none;
    margin: 20px 0;
    padding: 0;
    text-align: center;
}
.pages li {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 4px;
    text-indent: -999px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    background: #fff;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,.2);
}
.pages li:hover {
    background: #aaa;
}
.pages li.active {
    background: #666;
}

/* Controls */
.controls { margin: 25px 0; text-align: center; }

.supplier .topSly .selectComponent:after {
    top: 15px;
    margin-top: -2px;
}

.supplier .checkboxSlide div .currencyLabel {
    color: #d6dadc !important;
}

.label-day-selector {
    font-family: $cera !important;
    font-weight: 600 !important;
    font-size: $s-l !important;
    }

// input[name="repeat_active"]{
//     height: inherit !important;
// }

// input[name="repeat_active"] + label{
//         width: 25px;
//         height: 25px;
//         border: 1px solid black;
//     :before{
//         background-color: red;
//     }

// }

// input[name="repeat_active"] {
//         width: 25px;
//     height: 25px;
//     background: #fff;
//     background-image: none !important;
//     -webkit-appearance: none;
//     border-radius: 3px;
//     background-color: #d3ffdd;
//     border: solid 1px #93e4a5;
//     &:checked{
//         background-color: #d3ffdd;
//         border: solid 1px #93e4a5;

//     }
//     &:checked + label {
//         content: url(../images/svg/checked.svg);
//             // height: 11px;
//             width: 25px;
//             padding-right: 25px;
//     }
// }
.squaredThree {
    width: 20px;
    height: 20px;
    background: #fff;
    background-image: none !important;
    -webkit-appearance: none;
    background-color: #fff;
    border: solid 1px #93e4a5;
    border-radius: 3px;
    float: left;
    top: 9px;
    position: relative;
    margin-right: 10px;
}
.squaredThree label {
    width: 18px;
    height: 18px;
    margin: 0 !important;
    cursor: pointer;
    position: absolute;
    border-radius: 3px;
    /* top: 0; */
    /* left: 0; */
    border-radius: 3px;
    box-shadow: inset 0px 0px 0px transparent, 0px 1px 0px rgba(255, 255, 255, 0);

}
.squaredThree label:after {
    content: url(/fonts/checked.svg?966c02042830f1c9200515afc6fdf83d);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 2px;
    left: 2px;
    border: 0px solid #fcfff4;
    border-top: none;
    border-right: none;
    opacity: 0;


}
.squaredThree label:hover::after {
  opacity: 0.3;
}
.squaredThree input[type=checkbox] {
  // visibility: hidden;
  opacity: 0;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  z-index: 10;
  width: 25px;
    height: 25px;
}
.squaredThree input[type=checkbox]:checked + label{

    background-color: #d3ffdd;
}
.squaredThree input[type=checkbox]:checked + label:after {
    opacity: 1;
}

.package .checkboxSlide > label {
    font-size: 36px;
    line-height: 1;
}

#custom-rule-save {
    padding: 8px 15px !important;
        border: 2px solid #f0b323 !important;
}

.fileOptions {
    position: absolute;
    /* float: left; */
    /* width: 100%; */
    /* text-align: left; */
    display: block !important;
    margin-top: -55px !important;
    .addOption {
    padding: 13px 0px 0 0!important;}
}
form:focus,
button:focus,
input:focus {
    outline:none !important;
}

.fileOptions li {


    &:before{
    content: '\25CF';
    font-size:10px;
    display: block;
    top: 18px;
    bottom: 50%;
    position: absolute;
    color: $c-yellow;
    margin-left: -10px;}
    &:first-child:before {
        content: none;
    }

}

// .plusIcon:after {
//     @include menuicon;
//     background-image: url(../images/svg/plus-icon-y.svg);
// }

.supplier .lineHeader .buttonHeader.active {
    border: 1px solid #ffffff;
}

.supplier .stepHeader.noMargin {
    margin-bottom: 0px !important;
}

.deleteOption.delete-product{
    line-height: 19px !important;
     .red{
        line-height: 19px !important;
        padding-top: 8px;
        padding-bottom: 8px;
    }

}

.reset-selection {
    .deleteOption{
    margin-top: 50px !important;
}
}

.officeSpace, .houseRules {
    min-height: 800px;
}

.stepNavigation .buttonHeader:hover {
    background-color: white !important;
    border: 1px solid #fff;
}
.addNewProductOption{
    cursor: pointer;
}

.deleteOption {
    span{
        font-family: $cera-bold;
        font-size: $s-xs;
        text-align: left;
        color: #ff4a4a;
    }
}
.deleteModal{
    .modal-body{
        padding-bottom: 0px;
        p{
            font-family: $cera;
            font-size: 18px;
            line-height: 1.44;
            text-align: left;
            color: #131e29;
        }
    }
}

.warningBody {
    background-color: #fffbf3;
    padding: 30px;
    margin: 30px 0;
    p, li {
        font-family: $cera;
  font-size: 18px;
  text-align: left;
  color: #f0b323;
    }
}

.smallButtons {
    float: right;
    a.btnSave {
            padding: 8px 15px !important;
    border: 2px solid #f0b323 !important;
    }
}
.statusSidebar.edit{
    .inactiveOption{
        span:first-child{
            font-family: $cera;
            font-size: 24px;
            text-align: left;
            color: #131e29;
        }
        a{
            text-align: right;
        }
    }
}
// preview product style
//
.country_code{
    cursor: pointer;
}
.preview {

    .fa-pencil{

        &:before {
            content: url(../images/svg/edit-pencil.svg);
        }
    }
    .customGallery{
        .countImages {
            position: absolute;
            width: calc(100% - 30px);
            height: calc(100% - 0px);
            background-color: rgba(0, 0, 0, 0.5);
            top: 0px;
            color: #ffffff;
            text-align: center;
        }
    }
    .collapse-button{
        font-family: $cera-bold;
        font-size: 14px;
        text-align: left;
        color: #f0b323;
        &::after{

            content: url(../images/svg/contract-icon.svg);
            height: 3px;
            width: 6px;
            margin-left: 4px;
            margin-top: 2px;
            display: inline-block;
            position: absolute;
        }
        &.collapsed{
            &::after{
                content: url(../images/svg/expand-icon.svg);
                height: 3px;
                width: 6px;
                margin-left: 4px;
                margin-top: 2px;
                display: inline-block;
                position: absolute;
            }
        }
    }
    h1 {
        color: #272733;
    }
    h5{
        font-family: $cera;
        font-size: 14px;
        text-align: left;
        color: #a0abb2;
    }
    .statusSidebar .extraInfoSection h2.activeOption span:last-child{
        float: right;
    }
    .sidebarStatusCompany {
        span{
            float: left;
            &.totalNumber{
                float: right;
            }
        }
    }
    .checkboxSlide{
        padding-bottom: 45px;
        a{
            position: absolute;
            font-family: $cera;
            font-size: 14px;
            text-align: left;
            color: #a0abb2;
            margin-top: 5px;
        }
        input[type="checkbox"] + label{
            font-family: $cera;
            font-size: 24px;
            line-height: 1.42;
            text-align: left;
            color: #a0abb2;
        }
        input[type="checkbox"]:checked + label{
            font-family: $cera;
            font-size: 24px;
            line-height: 1.42;
            text-align: left;
            color: #131e29;
        }
    }
    .sectionTitle {
        text-transform: uppercase;
    }
    .margin30 {
        margin-bottom: 30px;
    }
    .margin60 {
        margin-bottom: 60px;
    }
    .grayBackground{
        background-color: #fafafa;
        padding-bottom: 60px;
    }
    .gallerySpacer {
        margin-bottom: 4.5%;
    }

    .mapType {
        max-height: 400px;
        overflow: hidden;
    }
    h2{
        margin-top: 55px;
        margin-bottom: 20px;
        font-family: $cera;
            font-size: 24px;
            text-align: left;
            color: #131e29;
        word-wrap: break-word;
        span{
            font-family: $cera;
            font-size: 24px;
            text-align: left;
            color: #131e29;
        }
        &.mediumStyle{
            a{
                font-weight: 300;
                color: #a7b1b8;
                padding: 0 15px 0 0;
                .glyph-arrow-left{
                    @include menuicon;
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    content: "";
                    margin-top: 6px;
                    position: absolute;
                    display: inline-block;
                    background-image: url('../images/svg/iconarrow-left.png');
                }
            }
            span{
                font-weight: 600;
                font-family: $cera;
                text-indent: 15px;
                display: inline-block;

            }
            font-weight: 600;
            font-family: $cera;
        }
    }
    h3 {
        font-family: $cera-bold;
        font-size: 14px;
        // font-weight: bold;
        text-align: left;
        color: #a0abb2;
        margin-top: 55px;
        margin-bottom: 35px;
    }
    p{
        margin-bottom: 25px;
        font-family: $cera;
        font-size: 18px;
        line-height: 1.44;
        text-align: left;
        color: #272733;
    }
    span{

    }
    .imageHolder {
        .map-office-form{
            margin-top: 0px;
            margin-bottom: 0px;
            margin: 0 0;
        }
    }
    .imageHolder img{
        width: 100%;

    }
    .detailInfo {
            label {
                margin: 0;
                padding:0;
                &.packageDescription{
                    margin-bottom: 0px !important;
                    padding-bottom: 0px !important;
                }
            }
            h3 + h2.centerAlign {
                display: table;
            }
            h2.centerAlign{
                text-align: center;
                display: inline-table;
                width: 100%;
                span{
                    font-family: $cera;
                    font-size: 24px;
                    line-height: 1.42;
                    color: #a0abb2 !important;
                    margin-bottom: 45px;
                    margin-top: 65px;
                    display: inline-table;
                }



            }


        h2 {
            margin: 0px !important;

            span{
                color: #272733;
            }

        }
        h3 {
            margin: 25px 0px 0px !important;
            &.customMargin{
                margin-top: 0px !important;
                margin-bottom: 20px !important;
            }
        }
    }
    hr {
      border:none;
      border-top:2px dotted #cdd1d4;
      color:#fff;
      background-color:#fff;
      height:1px;
      width:100%;
      margin-top: 55px;
      margin-bottom: 55px;
    }
    .checkboxSlide input[type="checkbox"] + label:after, .checkboxSlide input[type="radio"] + label:after {
        display: none;
    }
    .checkboxSlide input[type="checkbox"] + label:before, .checkboxSlide input[type="radio"] + label:before{
        width: initial;
        height: 10px;
        border-radius: unset;
        background-color: initial;
        right: 0px;
        margin-top: initial;
        top: initial;
        float: right;
        padding: 0;
        margin-top: 12px;
        margin-right: -30px;
        width: 15px;
        border-bottom: solid 2px #ff4a4a;
    }
    .checkboxSlide input[type="checkbox"] + label{
        padding-right: 30px;
        &:before{
            right:0;
            margin-right: 0;
        }
    }
    .checkboxSlide input[type="checkbox"]:checked + label:before, .checkboxSlide input[type="radio"]:checked + label:before {
        content: url(/fonts/check-icon-g.svg?0f2ef6d…);
        height: 15px;
        width: auto;
        margin-right: 5px;
        border-bottom: solid 0px #ff4a4a;
        background-color: initial;
        height: 0px;
        margin-top: -4px;
    }
}

.padding0{
    padding: 0 0 !important;
}
.padding30{
    padding: 0 30px;
}

.statusSidebar{
    .productStatus{
        padding: 55px 0 65px 0;
        display: inline-block;
        background-color: #131e29;
        width: 100%;
        margin-bottom: 55px;
        h3 span{
            font-family: $cera-bold;
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            color: #a0abb2;
            padding: 0px 0 25px 0;
        }
        h2.draft {
           button,
           a{
                border-radius: 3px;
                border: solid 2px #f0b323;
                background: none;
                background-image: none;
                font-family: $cera-bold;
                font-size: 14px;
                text-align: left;
                color: #f0b323;
                padding: 12px;
                float: right;
               margin-top: -8px;
            }
            span{

            font-family: $cera;
            font-size: 24px;
            text-align: left;
            color: #f0b323;
        }}
        h2.live {
            > span {
                font-family: $cera;
                font-size: 24px;
                text-align: left;
                color: #00ce2d;
            }
        }
        h2.review{
            > span {
                font-family: $cera;
                font-size: 24px;
                text-align: left;
                color: #007fff;
                line-height: 1.5;
            }
            p {
                font-family: $cera;
                font-size: 16px;
                font-style: italic;
                line-height: 1.5;
                text-align: left;
                color: #848e95;
                span{
                    font-weight: 500;
                    font-style: normal;
                    color: #f0b323;
                }
            }
        }
    }
    .extraInfoSection{
        padding-bottom: 50px;

        h3 span{
            font-family: $cera-bold;
            font-size: 14px;
            text-align: left;
            color: #a0abb2;
            text-transform: uppercase;
        }
        h2{
            &.inactiveOption{
                font-family: $cera;
                font-size: 24px;
                text-align: left;
                color: #a8b2b8;
                .addOption {
                    text-transform: uppercase;
                    float: right;
                    width: 60px;
                    line-height: 28px;
                }
                > span:last-child{
                    float: right;
                    padding: 0;
                    margin-top: 12px;
                    margin-right: 5px;
                    width: 15px;
                    border-bottom: solid 2px #ff4a4a;

                    &:before{
                        content: initial;
                        border-bottom: 2px solid red;

                    }
                }
            }
            &.activeOption{
                font-family: $cera;
                font-size: 24px;
                text-align: left;
                color: #131e29;
                span:last-child{
                    float: right;
                    padding: 0;
                    margin-top: -4px;
                }
            }
        }
    }
}
.countImages {
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    background-color: rgba(0, 0, 0, 0.5);
    top: 20px;
    color: #ffffff;
    text-align: center;
    span{
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% - 57%);
    }
}
.fa-pencil{
    position: absolute;
    //padding: 20px;
    font-size: 24px;
    color: #c4c3c8;
    right: 18px;
    z-index: 2;
    padding: 0 0 20px;
    margin-top: -5px;
}

.policyDetailsTable{
    table{
        margin-bottom: 55px;
        th {
            font-family: $cera-bold;
            font-size: 14px;
            text-align: left;
            font-weight: 300;
            color: #a0abb2;
            padding-bottom: 18px;
        }
        td {
            padding-bottom: 15px;
            span{
                width:100%;

                display: table-row;
                padding-bottom: 10px;
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.42;
                letter-spacing: normal;
                text-align: left;
                color: #272733;

            }
            span:first-child{
                font-family: $cera-medium;
                font-size: 18px;
                line-height: 1.42;
                text-align: left;
                color: #272733;

            }
            //span:last-child{
            //    font-family: $cera;
            //    font-size: 14px;
            //    text-align: left;
            //    color: #a0abb2;
            //
            //}
        }
        .percent {
            td{
                span{
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a0abb2;
                }
            }
        }
    }
}

.save-cancel-container{
    padding-top: 15px;
}

.mediumStyle{
    a{
        font-weight: 300;
        color: #a7b1b8;
        padding: 0 15px 0 0;
        .glyph-arrow-left{
            @include menuicon;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            display: inline-block;
            width: 20px;
            height: 20px;
            content: "";
            margin-top: 6px;
            position: absolute;
            display: inline-block;
            background-image: url('../images/svg/iconarrow-left.png');
        }
    }
    span{
        font-weight: 600;
        font-family: $cera;
        text-indent: 15px;
        display: inline-block;

    }
    font-weight: 600;
    font-family: $cera;
}

hr {
    border:none;
    border-top:2px dotted #cdd1d4;
    color:#fff;
    background-color:#fff;
    height:1px;
    width:100%;
    margin-top: 55px;
    margin-bottom: 55px;
}
.houseFiles{
    .inactiveOption{
        .checkConfirmation{
            padding: 8px 15px 0px !important;
            width: 10px;
            display: block;
            &:before {
                content: '';
                border-bottom: 2px solid red;
                width: 10px;
                /* height: 8px; */
                display: block;
            }
        }
        > span{
            float: right;
            padding: 0;
            margin-top: 12px;
            margin-right: 5px;
            width: 15px;
            content: '';
            //border-bottom: solid 2px #ff4a4a;

            &:before{
                content: '';
                border-bottom: 2px solid red;
                width: 10px;
                /* height: 8px; */
                display: block;

            }
        }
    }
}
//.statusSidebar .extraInfoSection{
//    span{
//        text-transform: capitalize;
//    }
//}
.editDraft {

    .houseRules{
        min-height: inherit;
    }
    h1,
    .mediumStyle span{
        color: #131e29;
    }
    h4,
    .sectionTitle{
        font-family: $cera-bold;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #a0abb2;
        text-transform: uppercase;
    }
    .formControl.smallButtons{
        margin-top: -15px;
        display: inline-block;
        position: absolute;
        text-align: right;
        right: 15px;
    }
    .detailInfo {
        label {
            margin: 0;
            padding:0;
            &.packageDescription{
                margin-bottom: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        h3 + h2.centerAlign {
            display: table;
        }
        h2.centerAlign{
            text-align: center;
            display: inline-table;
            width: 100%;
            span{
                font-family: $cera;
                font-size: 24px;
                line-height: 1.42;
                color: #a0abb2 !important;
                margin-bottom: 45px;
                margin-top: 65px;
                display: inline-table;
            }



        }


        h2 {
            margin: 0px !important;

            span{
                color: #272733;
            }

        }
        h3 {
            margin: 25px 0px 0px !important;
            &.customMargin{
                margin-top: 0px !important;
                margin-bottom: 20px !important;
            }
        }
    }
}
.supplier .checkboxSlide{

    input[type="checkbox"]{
        cursor: pointer;
        width: 100%!important;
    }
}
.supplier .checkboxSlide.equipment{
    input{
        display: inline-block;
        visibility: inherit !important;
    }
    input[type="checkbox"]{
        z-index: 20;
        width: 80px;
        height: 30px;
        border-radius: 100px;
        background-color: #f2f2f2;
        right: 20px;
        margin-top: 17px;
        opacity: 0;
    }
}
.supplier{
    .calendarHours{
        color: #272733;
    }
}
.statusSidebar .extraInfoSection{
    h2{
        &.inactiveOption{
            a{

            }
            .checkConfirmation{
                display: none !important;
            }
        }
        &.activeOption{
            a{
                display: none !important;

            }
            .checkConfirmation{
                float: right;
                padding: 0;
                margin-top: -5px;
            }
        }
    }
}

.package .checkboxSlide > label {
    font-size: 36px !important;
    line-height: 1;

}

.currencyLabel.previewLabel{
    line-height: 1;
    margin-top: 0 !important;
    font-family: $cera;
    font-size: 14px;
    text-align: left;
    color: #a0abb2 !important;
    span{
        font-family: $cera;
        font-size: 14px;
        text-align: left;
        color: #a0abb2;
    }

}

//@-moz-document url-prefix(){
//    .checkboxSlide select.selectComponent {
//        -moz-appearance: none;
//        background: url("../images/svg/drop-arrow-g.svg") right / 20px no-repeat #fff;
//        padding-right: 20px;
//    }
//}


@-moz-document url-prefix(){
    .checkboxSlide.selectSlide{
        .sel {
            display: inline-block;
            position: relative;
            vertical-align: middle;
        }

        .sel:before, .sel:after {
            content: "";
            pointer-events: none;
            position: absolute;
        }

        .sel:after {
            content: url("../images/svg/drop-arrow-g.svg");
            font-size: 0.625em;
            height: 1em;
            line-height: 1;
            margin-top: -0.5em;
            right: 1.2em;
            top: 50%;
            color:#fff;
        }
        .sel.disabled-arrow:after{
            content: none!important;
        }
        .sel:before {
            bottom: 0;
            right: 0;
            top: 0;
            width: 2em;
            //background-color: #405E00;
        }
    }
}
.preview .currencyLabel.previewLabel{
    vertical-align: top;
    line-height: 0.9;
}

.checkboxSlide.equipment .col-md-12 .col-md-8 input{
    border-radius: 3px 0 0 3px;
    border-right:0px solid #fff !important;
    background-image: linear-gradient(to left, #ffffff, #ffffff);
    text-transform: capitalize;

    &[disabled="disabled"]{
            color: #d6dadc!important;
        width: 100%;
        border-radius: 3px 0 0 3px;
        display: inline-block;
        background-image: linear-gradient(to left, #ffffff, #ffffff);

    }
}
.checkboxSlide.equipment .col-md-12 .col-md-4 input{
    border-radius: 0 3px 3px 0;
    &[disabled="disabled"]{

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #d6dadc!important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #d6dadc!important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #d6dadc!important;

        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #d6dadc!important;
        }
    }

}
.m-t-wkro {
    margin-top: 11px;
}
.checkboxSlide.amenities{
    margin-bottom: 0px !important;
    label{
        padding-bottom: 0px;
    }
}

.col-md-4.priceView .currencyLabel + input,
.col-md-4.priceView .currencyLabel + input{
border: 1px solid #93e4a5!important;
    &[disabled="disabled"]{
        border: solid 1px #f2f2f2!important;
    }
}
//.col-md-12 .col-md-8 input{
//    border-radius: 3px 0 0 3px;
//    border: 1px solid #cdd1d4;;
//    border-right: 0px solid #93e4a5 !important;
//}
//.col-md-12 .col-md-4 input{
//    border-radius: 0 3px 3px 0;
//    border-right: 1px solid #93e4a5 !important;
//}
.checkboxSlide input[type="checkbox"] + label,
.checkboxSlide.house-checkbox {
    line-height: 1.3;
    height: 80px;
    overflow: hidden;
}

.supplier :-ms-input-placeholder {
    font-style: normal!important;
}

.edit-closed-days .dateRangeEdit{
    width: 100%;
    text-indent: 30px;

}

.nameStyle{
    color: #131e29;
}