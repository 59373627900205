#activebtn{
  position: fixed;
  z-index: 601;
  right: 0px;
  top: 50px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin: bottom right;
  background: #333f48;
  padding: 0 15px 4px 15px;
  border: 0;
  span{
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 25px;
    font-family: "CeraGR-Bold";
  }
}
.tabSection {

  width: calc(72vw + -15px);
}
.statusSidebar,
.supplier .tipsTricks{
background-color:#f9f9f9;
  z-index: 500;
}
#secondarysuppliercontent{
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}
#mainsuppliercontent.fullwidth .tabSection{
  width: 100%;
}

.ambassador{
&-alert{
  border-radius: 2px;
  background-color: #fffbf3;
  padding: 30px;
  h1,h3{
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #131e29;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h1{
    font-weight: bold;
  }
  h3{
    font-weight: normal;
    line-height: 1.43;
  }
}
  &-title{
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #131e29;
    margin-bottom: 15px;
    img[src=""]{
      width:0px!important;
      margin-right: 0px!important;
    }
  }
  &-content{
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #a7b1b8;
  }
  &-share{
    margin-top: 30px;
    .showhidecontent {
      z-index: 1;
    }
  }
  &-notification{
    border-radius: 3px;
    border: solid 1px #fed87d;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    margin-bottom: 75px;
    span{
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #131e29;
    }
  }
  &-arrow_box {
    position: absolute;
    background: #ffffff;
    border: 1px solid #cdd1d4;
    border-radius: 3px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #cdd1d4;
    background-color: #ffffff;
    margin-top: 5px;
    ul{
      list-style: none;
      display: inline-block;
      padding: 0px 0px 0px 0px;
      margin-bottom: 0px;
      min-width: 180px;
      li{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #cdd1d4;
        .social-icon{
          width: 25px;
          height: 25px;
          display: inline-block;
          background-size: contain;
          background-position-y: center;
          margin: 15px;
          background-repeat: no-repeat;
          &.facebook{
            background-image: url("../images/testimonials/icon-facebook.svg");
          }
          &.twitter{
            background-image: url("../images/testimonials/icon-twitter.svg");
          }
          &.instagram{
            background-image: url("../images/testimonials/icon-instagram.svg");
          }
          &.linkedin{
            background-image: url("../images/testimonials/icon-linkedin.png");
          }
        }
      }
    }
  }
  &-arrow_box:after, &-arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: 1px solid #1a1717;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &-arrow_box:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #cdd1d4;
    border-width: 16px;
    margin-left: -16px;
  }
  &-arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
  }
}