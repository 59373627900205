.statusSidebar {
    z-index: 600;
}
.tabSection + .tab-content {
    padding-top: 122px;
}

.heroSection .hero-status-sent{
    border: 1px solid lightgray;
    border-radius: 4px;
}
@media only screen and (max-width: 1199px){
    .tabSection {
        width: calc(92vw + 2px)!important;
    }
}
.tabSection {
    background-color: #f2f2f2;
    padding-top: 45px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    border: 0 solid #fff!important;
    position: fixed;
    z-index: 400;
    width: calc(72vw + 2px);

    > div{
        margin-bottom: 17.5px;
        i{
            img {
                margin-right: 16.5px;
            }
        }
        span{
            font-family: $cera-medium;
            font-size: 24px;
            text-align: left;
            color: $c-darkblue;
            vertical-align: bottom;
            text-transform: uppercase;
        }
    }
    > ul.list-inline{
        margin-bottom: 0;
        li {
            padding-bottom: 1.6px;
            padding: 0 0!important;
            margin-right: 30px;
            margin-left: 5px;
            &:last-child{
                margin-right: 0px;
            }
            a{
                font-family: $cera-bold;
                font-size: 14px;
                font-weight: 400;
                text-align: left;

                color: #a0abb2;
                text-transform: uppercase;

            }
            &.active {
                border-bottom: 3px solid $c-yellow;
                a{
                    color: $c-darkblue;
                }
            }

        }
    }
}
.tab-pane.active{
    .h2 {
        font-family: $cera;
        font-size: 36px;
        text-align: left;
        color: $c-darkblue;
        padding: 0 0;
        margin: 0 0;
        line-height: 1;
        margin-top: 54px;
        margin-bottom: 10px;
    }
    .grayBold{
        font-family: $cera;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: $c-gray;
        margin-bottom: 5px;
    }
    .grayLight{
        font-family: $cera;
        font-size: 18px;
        line-height: 1.33;
        text-align: left;
        color: $c-gray;
    }
    .subSectionName{
        font-family: $cera-bold;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: $c-lightgray;

        margin-bottom: 0px;
        &.main{
            margin-top: 20px;
        }
        &.second{
            margin-top: 50px;
        }

    }
    .p{
        font-family: $cera;
        font-size: 18px;
        line-height: 1.44;
        text-align: left;
        color: $c-darkblue;
    }
    .interested {
        font-family: $cera;
        font-size: 24px;
        text-align: left;
        color: #131e29;
    }
    .editSection{
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 100;

    }
    .ibox-content,
    .contact-box {
        padding: 15px 15px;
    }
    .ibox-content {
        background-color: #ffffff;
        color: inherit;
        padding: 15px 15px 15px 20px;
    }
    .fixIbox{
        //padding: inherit!important;
    }
    hr {
        margin-top: 10px;
        margin-bottom: 30px;
        //&:last-child{
        //    display: none;
        //}
    }
}
.tabs-container .panel-body,
.wrapper {
    padding: 0 15px !important;
}

.tabs-container .panel-body {
    border: 0px solid #e7eaec;
}

.memberBox{
    margin-bottom: 60px;
    div.userImage{
        border-radius: 3px;
        background-color: #d8d8d8;
        overflow: hidden;
        width: 120px;
        height:120px;
        vertical-align: middle;
        display: inline-block;
        line-height: 120px;
        font-family: $cera;
        font-size: 48px;
        font-weight: 600;
        text-align: center;
        color: #131e29;
        img{
            width:100%;
            height:auto;
            //display: inline-block;
            vertical-align: middle;
            text-align: center;
        }
    }
    .rightBox {
        //padding-left: 35px!important;
    }
    .topSection{
        margin-bottom: 6.5px;
        h3{
            font-family: $cera;
            font-size: 24px;
            color: #131e29;
            padding: 0 0;
            margin: 0 0;
        }
        > strong {
            font-family: $cera;
            font-size: 16px;
            font-style: italic;
            line-height: 1.5;
            text-align: left;
            color: #848e95;
            padding: 0 0;
            margin: 0 0;
            font-weight: 400!important;
        }
    }
    .bottomSection{
        span {
            display: block;
            font-family: $cera;
            font-size: 18px;
            line-height: 1.56;
            text-align: left;
            color: #131e29;
            padding: 0 0;
            margin: 0 0;
            &:first-child{
                text-transform: capitalize;
            }
        }
    }
}
.contactPerson{
    &:first-child{
        margin-top: 50px;
    }
    .subSectionName{
        margin-bottom: 17px;
    }
    .subSectionName {
        padding-bottom: 17px;
        .create_new_person {
            position: absolute;
            z-index: 50;
            right: 0;
            .addNewBig {
                margin-top: 0px;
                height: 40px;
                width: 40px;
                .bigPlus{
                    background-size: 23px 29px;
                    width: 22px;
                    height: 25px;
                    margin-top: 6px;
                }
            }
        }
    }


}
.createNewContact {
    text-align: center;
    >a {
        margin-right: 15px;
    }
    > span {
        vertical-align: super;
        font-family: $cera;
        font-size: 24px;
        text-align: left;
        color: #131e29;

    }
}
.contactBoxGrid {
    display: inline-block;
    width:100%;
}

.supplier .showHoverButtons .deleteOption {
    margin-top: 0px;
}
.showHoverButtons {
    //position: absolute;
    //top: 40%;
    //text-align: center;
    //width: 80%;
    position: absolute;
    top: 25%;
    text-align: center;
    left: 45%;
    > a {
        display: inline-block;
        position: relative;
        z-index: 300;
       &:first-child{
           padding-right: 10px;
       }
    }
    .btnSave{


      &.active {
          span{
              font-family: $cera-bold;
              font-size: 14px;
              text-align: left;

              border-radius: 3px;
              background-color: #ffffff;
              border: solid 2px #f0b323;
              color: #f0b323!important;
              padding: 10px 15px;
              text-transform: uppercase;
          }
      }
    }
    .basicButton{
        line-height: inherit;
        float: inherit;
    }
}
#wrapper.supplier{
    padding-top: 79px;
}
.supplier {
    .workero-topnav {
        position: fixed;
        display: block;
        z-index: 400;
        top: 0;
    }
    .supplierNav {
        ul.nav{
            position: fixed;
            z-index: 300;
            width: 100px;
        }
    }
}


.showHoverButtons{
    display: none;
}

.user-data-container:hover .showHoverButtons{
    display: block;
}

.user-data-container:hover .hoverEdit{
    opacity: 0.2;
}
//.tipsTricks{
//    .modal.deleteModal {
//        position: fixed;
//        z-index: 2050!important;
//    }
//}
.fixedDisplay{
    position: fixed;
}
.statusSidebar {
    height: 100%;
    max-height: 100vh;
    position: fixed;
    //width: 100%;
    width: 24%;
    overflow-y: auto;
    height: calc(100vh - 80px);
    background-color: #f2f2f2;
    //width: 24%;
}
.statusSidebar.companyTabInfo {
    height: 100%;
    max-height: 100vh;
    position: fixed;
    //width: 100%;
    width: 24%;
    h3,
    h2 {
        padding: 0;
        margin: 0;

        span{
            padding: 0;
            margin: 0;
        }

    }
    .extraInfoSection{
        padding-bottom: 0!important;
        h3 {
            padding-top: 58px;
            span{
                margin-bottom: 7px!important;
            }
        }
        h2{
            padding-top: 16px;

        }
    }

    .sectionTitle {
        font-family: $cera-bold;
        font-size: 14px;
        text-align: left;
        color: #a0abb2;
        margin-bottom: 23px;;
        display: block;
        text-transform: uppercase;
    }
    .productStatus{
        margin-bottom: 0px;
    }
    a{

    }
}

.active-images {
    background-color: #f2f2f2;
    text-align: center;
    min-height: 290px;
    padding-left: 15px;
    padding-right: 15px;

    img {
        width: 50.3px;
        margin: auto;
        display: block;
        padding-top: 84px;
        padding-bottom: 5%;
    }
    h2 {
        font-family: $cera;
        font-size: 24px;
        text-align: center;
        color: #a7b1b8;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    span {
        font-family: $cera;
        font-size: 18px;
        line-height: 1.44;
        text-align: center;
        color: #a7b1b8;
    }
    .labelFormat{
        font-family: $cera-bold;
        font-size: 14px;
        text-align: left;
        color: #a0abb2;
    }
}

.status_live {
    color: #00ce2d!important;
}

.status_in_progress {
    color: #0080ff!important;
}

.status_draft {
    color: #f0b323!important;
}

.status_closed {
    color: #f0b323!important;
}
.totalNumber {
    color: #7d878e;
}
.sidebarStatusCompany {
    .sectionTitle{
        text-transform: uppercase;
    }
    .activeOption {
        color: #fff;
        .totalNumber{
            color: #fff;
            float: right;
        }
    }
}


.secondTop {
    margin-top: 35px;
}

.supplier .gridTitleSpace {
    margin-top: 30px;
    h2 {
        margin-bottom: 35px;
    }
    a span {
             position: relative;
             bottom: -20px;
         }
}

.emptyGridList {
    max-width: 716px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    span {
        margin-top: 20vh;
    }
    h1 {
        font-family: $cera;
        font-size: 36px;
        text-align: center;
        color: #131e29;
        margin-bottom: 13px;
        ;
    }
    p {
        font-family: $cera;
        font-size: 18px;
        line-height: 1.44;
        text-align: center;
        color: #a7b1b8;
    }
}

.supplier .workero-topnav .workero-search {
    width: calc(96% - 448px);
}

.navbar-top-links.navbar-right.topMenu {
    line-height: 80px;
    > li {
        vertical-align: middle;
        > a {
            padding: 0 8px !important;
            i{
                vertical-align: middle;
            }
        }
    }
}

#page-wrapper {

    min-height: calc(100vh - 80px)!important;
}

.bootstrap-select.form-control {
    height: 50px;
    button {
        background-image: linear-gradient(to top, #ffffff, #f7f7f7);
        border: solid 1px #cdd1d4;
        height: 50px;
    }
}

.companyEditSave {
    float: right;
    margin-right: 10px;
    margin-top: -18px;
    border-radius: 3px;
    border: solid 2px #f0b323;
    font-family: $cera-bold;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    color: #f0b323;
    background-image: transparent!important;
    background: transparent!important;
    padding: 8px 18px;
}

a.btnCancel {
    font-family: $cera-bold;
    font-size: 14px;
    text-align: left;
    color: #a0abb2;
    text-transform: uppercase;
}


.financialEdit {

    text-align: right;
    //a{
    //    vertical-align: super;
    //    padding: 0 15px;
    //}

}

.bank_financial {
    label{
        font-family: $cera!important;
        font-size: 18px!important;
        line-height: 1.56!important;
        text-align: left!important;
        color: #131e29!important;
        text-transform: inherit!important;
    }
}
.previewFinancial {
    h2{
        margin-top: 0px;
        margin-bottom: 23px;
    }
}
.infoFinNumber {
    h2 {
        margin-top: 45px;
        font-family: $cera;
        font-size: 36px;
        text-align: left;
        color: #131e29;
    }
}

.infoFinNumberBold{
h1{
    font-family: $cera-bold;
    font-size: 24px;
    text-align: left;
    color: #131e29;
    margin-top: 20px;
    font-weight: normal;
}
}
.restrictionTab{

    display: inline-block;
    .groupRestriction{
        padding-top: 54px;
        font-family: $cera;
        font-size: 24px;
        text-align: right;
        display: inline-block;
        color: #a5b0b6;
        line-height: 1;
        font-weight: 400;
        &.active{
            color: #131e29;

        }
    }
    a{
        padding-top: 23px;
        color: #a0abb2;
        font-family: $cera-bold;
        font-size: 14px;
        text-align: right;
        display: inline-block;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        &.active{
            color: #131e29;
        }
        &:hover
        {
          color: #131e29;
        }
    }
}

.error-label-red{
    font-family: $cera!important;
    font-size: 10px!important;
    text-align: left!important;
    color: #ff4a4a!important;
    text-transform: inherit!important;
}
.leftTabMenu{
    width: 30%;
    padding: 0 0;
    margin: 0 0;
    position: relative;
    float: left;
    display: inline-block;
    background-color: #fafafa;
    background-color: #fafafa;

    margin-left: -30px;
    position: fixed;
    height: 100vh;
    max-width: 300px;
    text-align: right;
    padding-right: 14px;
    padding-top: 3px;
}
.rightTabContent{
    width:calc(100% - 300px);
    float: right;
    position: relative;
    padding: 0 0;
    margin: 0 0;
    display: inline-block;
    h1 {
        font-family: $cera;
        font-size: 24px;
        text-align: left;
        color: #a5b0b6;
        margin-bottom: 26px;
        line-height: 1;
    }
    .checkbox > label,
    .labelRestrict{
        font-family: $cera;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #131e29;
        text-transform: capitalize;
        margin-bottom: 30px;
        margin-top: 0px;

    }
    .companyEditSave{
        margin-top: 32px;
        margin-bottom: 00px;
    }
    .checkbox {
        margin-top: 0;
        margin-bottom: 0px;
        .squaredThree{
            border: solid 0px #cdd1d4!important;
            margin-top: -4px;
            margin-right: 0px;
        }
        input{
            height: 22px;
            margin-left: -12px;
            + label {
                height: 18px;
                padding-left: 18px;
                border: solid 1px #cdd1d4!important;
            }
            &:checked + label {
                border: solid 1px #93e4a5!important;
            }
            + label:after {
                content: url(/fonts/checked.svg?966c020…);
            }
        }

    }
}

.statusSidebar,
.heroSection {
    .hero-status-added{
        font-size: 8px;
        vertical-align: middle;
        color: #ffdd4d;
        margin-top: -2px;
        margin-right: 10px;
    }
    .hero-status-live{
        font-size: 8px;
        vertical-align: middle;
        color: #2eac63;
        margin-top: -2px;
        margin-right: 10px;
    }
    .hero-status-sent{
        font-size: 8px;
        vertical-align: middle;
        color: #ffffff;
        margin-top: -2px;
        margin-right: 10px;
    }
    .hero-status-cancelled{
        font-size: 8px;
        vertical-align: middle;
        color: #e54d42;
        margin-top: -2px;
        margin-right: 10px;
    }
    h2.activeOption {
        .totalNumber{
            font-family: $cera;
            font-size: 24px;
            text-align: right;
            color: #7d878e;
        }
    }
    .total{
        span{
            font-family: $cera;
            font-size: 36px;
            text-align: center;
            color: #ffffff;
        }
        span.text{
            font-family: $cera-bold;
            font-size: 14px;
            text-align: center;
            color: #a0abb2;
            text-transform: uppercase;
        }
    }
    .fa.fa-circle + span {
        display: inline-block!important;
    }
    .bottomSection h2{
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.heroSection{
    .glyph-heroes {
        opacity: 0.5;
        vertical-align: sub;
        margin-right: 10px;
    }
}

.heroSection {
    .memberBox .topSection h3 {
        text-transform: capitalize;
    }
    .bottomSection > span {
        font-family: $cera;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #848e95;
    }
    .memberBox .topSection > strong{
        font-family: $cera;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #131e29;
        font-weight: 400!important;
        font-style: inherit!important;
    }
    .hero-status-added{
        font-size: 8px;
        vertical-align: middle;
        color: #ffdd4d;
        margin-top: 0px;
        margin-right: 10px;
        + span {
            color: #ffdd4d;
            text-transform: uppercase;
            font-family: $cera-bold;
            font-size: 14px;
        }

    }
    .hero-status-live{
        font-size: 8px;
        vertical-align: middle;
        color: #2eac63;
        margin-top: 0px;
        margin-right: 10px;
        + span {
            color: #2eac63;
            text-transform: uppercase;
            font-family: $cera-bold;
            font-size: 14px;
        }

    }
    .hero-status-sent{
        font-size: 8px;
        vertical-align: middle;
        color: #ffffff;
        margin-top: 0px;
        margin-right: 10px;
        + span {
            color: #a0abb2;
            text-transform: uppercase;
            font-family: $cera-bold;
            font-size: 14px;
        }

    }
    .hero-status-cancelled{
        font-size: 8px;
        vertical-align: middle;
        color: #e54d42;
        margin-top: 0px;
        margin-right: 10px;
        + span {
            color: #e54d42;
            text-transform: uppercase;
            font-family: $cera-bold;
            font-size: 14px;
        }

    }
}

.squaredThree.disabled-checkbox {
    border: solid 1px #f2f2f2;
    background-color: #f2f2f2;
    label{
        background-color: #f2f2f2;
        &.disabled-label{
            color: #f2f2f2;
        }
    }
    label:after{
        opacity: 0.1!important;
        background-color: #f2f2f2;
    }
    input[type=checkbox]:checked + label {
        background-color: #f2f2f2;

    }
}

.squaredThree + label{
    &.disabled-label{
        color: #d6dadc;
    }
}

.controlCodeSelect {
    select {
        width:100%;
        text-indent: 15px;
    }
}

.disableMenu a{

    .glyph-company:after{
        background-image: url('../images/svg/glyph-company.svg')!important;
    }
    .glyph-offices:after{
        background-image: url('../images/svg/glyph-offices.svg')!important;
    }
    .glyph-heroes:after{
        background-image: url('../images/svg/glyph-heroes.svg')!important;
    }
    .glyph-calendar:after{
        background-image: url('../images/svg/glyph-calendar.svg')!important;
    }
    .glyph-events:after{
        background-image: url('../images/svg/glyph-events.svg')!important;
    }
    .glyph-financial:after{
        background-image: url('../images/svg/glyph-financial.svg')!important;
    }
    span{
        color: #21282d!important;
    }
}

.panel-body .editSection img {
    margin-top: 18px;
    margin-right: 15px;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option{
    font-family: $cera;
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
    text-align: left;
    color: #848e95;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option{
    font-family: $cera;
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    color: #272733;
    font-style: normal;
}
.bootstrap-select.btn-group .dropdown-toggle[title="Choose one or multiple skills"] .filter-option,
.bootstrap-select.btn-group .dropdown-toggle[title="Choose one or multiple subindustries"] .filter-option {
    font-family: $cera;
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
    text-align: left;
    color: #848e95;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    font-family: $cera;
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    color: #272733;
}

.selectComponent:after {
    content: url(/fonts/drop-arrow-g.svg?56f7c79…);
    position: absolute;
    margin-left: 168px;
    margin-top: -37px;
    z-index: 5;
    pointer-events: none;

}
.bootstrap-select.btn-group{
    .dropdown-menu > li > a {
        margin: 0;
        padding: 7.5px 15px;
    }

    .dropdown-menu li a.opt{
        padding-left: 15px!important;
    }

    .dropdown-header {
        padding: 7.5px 15px!important;
    }
}
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus{
    outline: none!important;
}
.bootstrap-select.btn-group .dropdown-menu{
    width:100%;
    overflow-x: hidden;
}
.bootstrap-select.btn-group .dropdown-menu,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus{
    border: solid 0.5px #ffd87d;
    box-shadow: 0 0 3px rgba(255, 216, 125, 0.7);
}
.open .dropdown-toggle.btn-default,
.bootstrap-select.open{
    background-image: linear-gradient(to top, #fffefa, #fffcf3);
    border: solid 1px #ffd87d;
}
.error-label.error-label-red{
    font-family: $cera-bold!important;
    font-size: 14px!important;
    text-align: left!important;
    color: #ff4a4a!important;
    background-color: transparent!important;
}

.statusSidebar.companyTabInfo {
    right:0;
}
#office-financial-container,
#company-financial-container {
    .form-group{
        margin-bottom: 0;
    }
    label {
        margin-top: 0;
    }
}

.lastChild:last-child {
    hr {
        display: none;
    }
}

.previewFinancial {
    .col-md-4{
        h2 {
            margin-top: 0px;
            margin-bottom: 13px;
        }
        label {
            line-height: 1;
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    .editSection {
        img {
            margin-right: 25px;
        }
    }

}

//{
//    font-family: $cera-bold;
//    font-size: 24px;
//    text-align: left;
//    color: #131e29;
//}

a.sectionAddPerson {
    margin-right: 20px;
    margin-top: -15px;
}
.tab-pane.active hr.addPersonHr {
    margin-bottom: 47px;
}

.formStyleControl {
    .control-label {
        margin-top: 0px;
    }
    .form-group {
        margin-bottom: 0px;
    }
    &.tab-pane.active hr {
        margin-top: 25px;
        margin-bottom: 40px;
    }
}

a.fixRightPosition {
    top: -12px;
    padding: 0 15px;
    position: relative;
}

.sk-spinner-cube-grid .sk-cube {
    background-color: #f0b323!important;
}

#modal-new-person .right-modal .selectComponent:after {
    margin-left: calc( 100% - 60px) !important;
    top: 85px;
    position: absolute;
    width: 15px;
    pointer-events: none;
}
#modal-new-person{
    .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover{
        background-color: #f0b323!important;
    }
    .form-group {
        margin-bottom: 0px;
        > label {
            margin-top: 0px;
        }
    }

}
.selectComponent{
    .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover{
        background-color: #f0b323!important;
    }
}

.addNewBig:focus{
    outline: none;
}

.memberBox {
    .rightBox .hoverEdit{
        h3,
        strong,
        span{
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
//
//@media only screen
//and (max-width: 1366px) {
//    .memberBox div.userImage {
//        width: 100px;
//        height: 100px;
//        line-height: 100px;
//    }
//}

.memberBox.hoverSection:hover {
    .hoverEdit,
    .userImage {
        opacity: 0.2;
    }
    .showHoverButtons {
        display: block;
    }
}
.caretPosition.selectComponent:after{
    margin-left: calc(100% - 60px);
    width: 15px;
    pointer-events: none;
}

#modal-new-person .right-modal .caretPosition.selectComponent:after {
    margin-left: -23px!important;
    top: 62px!important;
    pointer-events: none;
}
.selectComponent .bs-caret {
    display: none;
}

.rightTabContent .checkbox > label, .rightTabContent .labelRestrict {

    margin-top: 2px;
    padding-left: 9px;
}

.statusSidebar.heroInformations{
    .positionFormula{
        top: -12vw!important;
    }
    .productStatus{
        margin-bottom: 0px;
    }
}
.limitLength{
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    position: relative;
}

.statusSidebar.heroInformations .positionFormula {
    margin: 0 auto;
    text-align: center;
    top: 10vw !important;
    position: absolute;
    width: 86%;
}
.total.positionFormula {
    &.number{
        top: 8vw !important;
    }
}