.uploadXlsNotification {
    min-height: 54px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #ffd87d;
    padding-top: 19px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right:20px;
    font-family: $cera;
    font-size: 18px;
    text-align: left;
    color: #131e29;
    line-height: 1;
    margin-bottom: 50px;
    a {
        font-family: $cera-bold;
        color: #f0b323;
    }
}
#add-new-hero,
#upload-xls-file {
    line-height: 28px;
}
.supplier .stepHeader.heroSection {
    margin-bottom: 30px;
}

//#add-new-hero {
//    font-family: $cera;
//    font-size: 24px;
//    text-align: left;
//    color: #131e29;
//    line-height: 1;
//}

.uploadError {
    font-family: $cera;
    font-size: 24px;
    text-align: left;
    color: #ff4a4a;
}

.workero-search {
    .form-control{
        font-family: $cera!important;
        font-style: normal!important;
    }
    ::-webkit-input-placeholder {
        font-style: normal;
    }
    :-moz-placeholder {
        font-style: normal;
    }
    ::-moz-placeholder {
        font-style: normal;
    }
    :-ms-input-placeholder {
        font-style: normal;
    }
}

.pace .pace-progress {
    background: #F0B323!important;
}

#add-new-hero.displayNone + span {
    display: inline-block!important;
    font-family: $cera;
    font-size: 24px;
    text-align: left;
    color: #131e29;
    margin-top: -6px;
}

.phoneHeroes {
    .caretPosition {
        &.selectComponent:after {
            content: url(/fonts/drop-arrow-g.svg?56f7c79…);
            position: absolute;
            margin-left: -23px!important;
            margin-top: 20px!important;
            display: inline-block;
            z-index: 5;
            pointer-events: none;
        }
    }

}
.selectComponent.fullSelect:after {
    margin-left: calc(100% - 60px);
}
.addNewHero {
    label {
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .form-group {
        margin-bottom: 0px;
    }
    .selectComponent.fullSelect:after {
        margin-left: calc(100% - 60px);
        display: block;
    }
}
.heroDetailPreview,
.xsl-hero-preview {
    .tab-pane.active .editSection {
        top: 5px;
    }
    .editSection img {
        margin-top: 0px!important;
    }
    span {
        font-family: $cera;
        font-size: 24px;
        text-align: left;
        color: #272733;
        line-height: 1;
        margin-bottom: 20px;
        display: inline-block;
    }
    span.lastName {
        font-family: $cera-bold!important;
    }
}
.heroDetailPreview label,
.hero-container div > label {
    width: 100%
}

.stepHeader h2.titleStep{
    font-family: $cera-medium;
    font-size: 24px;
    text-align: left;
    color: #131e29;
}
.positionFormula{
    text-align: center;
    position: relative;
    top: -14vw;
}
.totalErors {
    font-family: $cera;
    font-size: 24px;
    text-align: left;
    color: #ff4a4a;
    padding-top: 10px;
    padding-bottom: 40px;
}

.color-red {
    span{
        color: #ff4a4a;
    }
}

span.euroSignFormat{
   font-family: sans-serif!important;
}
.heroProfileEdit {

}
.heroAdded .selectComponent.fullSelect:after{
    margin-top: -63px;
}
.heroContactUs {
    font-family: $cera;
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
    text-align: left;
    color: #848e95;
    a{
            font-style: $cera-medium;
            font-style: normal;
            color: #f0b323;
    }
}

.editSection.heroProfile {
        margin-right: 0px!important;
        padding-right: 0px!important;
        img{
            margin-right: 0px!important;
            margin-top: 30px;
        }
}
.heroPreviewSection {
    .heroPreviewName {
        font-family: $cera;
        font-size: 36px;
        text-align: center;
        color: #272733;
        padding-left: 25px;
        padding-right: 15px;
        vertical-align: sub;
        strong {
            font-weight: normal!important;
            font-family: $cera-medium;
        }
    }
}

.officeIcon.forHeroes{
    background-image: url(/fonts/heroes-y.svg?66c2f0c…);

}
.redText {
    font-family: $cera;
    font-size: 24px;
    line-height: 1.42;
    text-align: left;
    color: #ff4a4a;

}
.deleteHeroes{
    .memberBox {
        margin-bottom: 15px;
    }
    h2{
        margin-top: 0px!important;
    }
}


.supplier .fixDropDown .selectComponent:after {
    display: inherit;
    margin-left: calc(100% - 25px);
    margin-top: -28px;
    pointer-events: none;
}