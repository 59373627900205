// Basic Colors
$navy: #1ab394;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warrning color
$red: #ED5565;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;


// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profile-pattern: url("patterns/header-profile.png");
$nav-text-color: #a7b1c2;

// Colors
$first-color: rgba(121, 157, 218, 0.25);

$second-color: #222e3a;
$ssecond-color: #212121;
$third-color: #2F4050;
$sthird-color: #2e2e2f;
$fourth-color: #f2b527;
$sfourth-color: #7cba00;

$transform: rotate(245deg) translate(500px, -25px);
$transform-origin: 0 0;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;


// Font family
$cera: "CeraGR-Regular";
// $cera-italic: "CeraGR-RegularItalic", sans-serif;
$cera-bold: "CeraGR-Bold"; 

// $cera: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$cera-italic: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// $cera-bold: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$cera-medium: "CeraGR-Medium";
$cera-mediumItalic: "CeraGR-MediumItalic";
// Font size
$s-xxs: 10px;
$s-xs: 14px;
$s-s: 16px;
$s-m: 18px;
$s-l: 24px;
$s-xl: 36px;

//Color
$c-darkblue: #131E29;
$c-lightblue: #7D878E;
$c-darkgray: #333f48;
$c-gray: #848E95;
$c-lightgray: #A0ABB2;
$c-white: #ffffff;
$c-yellow: #F0B323;
$c-red: #ff4a4a;
$c-darkergray: #272733;
$c-green: #00ce2d;
$c-vlightgray: #a7b1b8;