.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead{
    border-color: #e8ebf2;
}
.fc-unthemed td.fc-today {
    background: #fffbf3;
}

.fc-event, .fc-agenda .fc-event-time, .fc-event a {
    padding: 4px 6px;
    background-color: #ffd87a;
    border: 0px solid #fff;
    border-color: #1ab394;
    color: #fff;
}

.fc-widget-content{
    span{
        font-family: $cera-b;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #a0abb2;
    }
}

.fc-event{
    .fc-content{
        .fc-time span,
        span.fc-time{
            font-family: $cera-b;
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            color: #333f48;
        }
        .fc-title{
            font-family: $cera-r;
            font-size: 10px;
            text-align: left;
            color: #131e29;
        }
    }
}
.fc-header-toolbar{
    .fc-state-default{
        border-radius: 3px;
        background-image: linear-gradient(to bottom, #ffffff, #f6f6f6);
        border: solid 1px #cdd1d4;
        font-family: $cera-b;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #a0abb2;

        &.fc-state-active{
            border-radius: 3px;
            background-image: linear-gradient(to top, #ffffff, #f6f6f6);
            border: solid 1px #cdd1d4;
            font-family: $cera-b;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #131e29;
        }
    }
}

.calendar {


    .fc-next-button,
    .fc-prev-button{
        &.fc-state-default{
            width: 37.2px;
            height: 40px;
            border-radius: 3px;
            background-image: linear-gradient(to bottom, #ffffff, #f6f6f6);
            border: solid 1px #cdd1d4;
            border-color: #cdd1d4;
            padding: 0;
        }
    }
    .fc-state-default {
        border: 1px solid;
        background-color: transparent;
        background-repeat: no-repeat;
        border-color: #cdd1d4;
        color: #a0abb2;
        text-shadow: none;
        box-shadow: none;
    }

}


.btn-white-small.bgDark{
    background-color: #21282d;
    &:hover{
        &:hover{
            background-color: #21282d;
        }
    }

    &.checkOut{
        border: 2px solid #d6dadc;
        color: #d6dadc;
    }
}
.displayBlock{
    display: block;
}
#calendar-checkin-popup{
        display: none;
        border-radius: 3px;
        background-color: #131e29;
        padding: 15px;

        width: 270px;
        padding: 20px;
        //padding-bottom: 60px;
        border-radius: 3px;
    z-index: 1000;
        .popupTitle{
            font-family: $cera-b;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            margin-bottom: 15px;
            display: inline-block;
        }
        td{
            font-family: $cera-r;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            padding-bottom: 15px;
            min-width: 85px;
            &.status_active{
                color: #00ce2d;
            }
            .companyStyle{
                font-family: $cera-r;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #a0abb2;
            }
        }
        td.no-anim{
            font-family: $cera-b;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #a0abb2;
        }

}

.fc-agendaDay-view {

    .fc-time,
    .fc-title{
        display: inline-block;
        span{
            opacity: 0.9;
            font-family: $cera-r;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #333f48;
        }

    }
    .fc-time{
        span{
            font-family: $cera-b!important;
            font-weight: bold!important;
        }
    }


}

.calendar .fc-state-default{
    min-width: 88px;
    min-height: 40px;
    opacity: 0.9;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f6f6f6);
    border: solid 1px #cdd1d4;
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #a0abb2;
}

.fc .fc-toolbar>*>:first-child{
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a0abb2;
    text-transform: uppercase;
}

.fc-unthemed th{
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a0abb2;
    text-transform: uppercase;
}
.calendar .fc-next-button.fc-state-default, .calendar .fc-prev-button.fc-state-default{
    min-width: inherit!important;
}

.fc-widget-content span{
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #131e29;
}

.fc-event{
    border-top: 1px solid white;
    border-right: 1px solid white;
}

.calendarHeaderIcon{
    content: none!important;
    background-image: url(../images/svg/calendarheader.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 30.5px!important;
    width: 29.5px!important;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    vertical-align: bottom;
    margin-right: 15px;
}
.ibox-content{
    border-color: white;
    padding: 15px;
}
.calendarHeader{
    margin-top: 50px;
    margin-bottom: 15px;
    i{

    }
    span{
        font-family: $cera-m;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131e29;
    }
}
.fc-list-table{
    .fc-list-heading{
        .fc-widget-header{
            background-color: #f6f6f6;
            border: solid 1px #e8ebf2;
            span{
                font-family: $cera-b;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #a0abb2;
                text-transform: uppercase;
            }
        }
    }
    .fc-list-item{
        .fc-event-dot{
            background-color: #ffd87a;
            width: 14px;
            height: 14px;
            border-radius: 14px;
        }
        td{
            opacity: 0.9;
            font-family: $cera-r;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #333f48;
        }
    }
}
.greenBg{
    background-color: #baffe9;
    //margin-bottom: 55px;
    padding: 30px 35px 30px 30px;
}
.pinkBg{

}

.calendarTopHeader{

    .leftArrow{
        content: none!important;
        background-image: url(../images/svg/returnArrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        height: 22px!important;
        width: 19px!important;
        display: inline-block;
        margin-right: 5px;
    }
    .inlineBlock{
        display: inline-block;
        vertical-align: middle;
    }
    .displayBlock {
        display: block;
        vertical-align: middle;
    }
    .companyName{
        font-family: $cera-b;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a0abb2;
    }

    .status{
        &:first-child{
            line-height: 1;
        }
        &.upcoming{
            font-family: $cera-r;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #f0b323;
        }
    }

}
.inlineBlock{
    display: inline-block;
}
.displayBlock{
    display: block;
}
.textGray{
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a0abb2;
}

.textNavy{
    font-family: $cera-r;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #272733;
    & + .textNavy.pull-right{
        margin-bottom: 0px;
    }
}

.detailBooking{
    .textGray{
        margin-bottom: 5px;
    }
    .grayBg{
        background-color: #fafafa;
        .customPadding{
            padding-top: 25px;
            padding-bottom: 0px;

        }
    }
    .textNavy{
        margin-bottom: 23px;
    }
    .sectionBrake{
        height: 50px;
        width: 100%;
        position: relative;
        display: inline-block;
    }
    .description{
        font-family: $cera-r;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131e29;
        margin-bottom: 23px;
    }
    .heroInfo{
        overflow: hidden;
        .inlineBlock{
            vertical-align: top;
        }
        img {
            width: 100%;
            max-width: 120px;
        }
    }
}

.officeTypeHeader{
    font-family: $cera-m;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #131e29;
}
#calendar-checkin-popup .status.no_show{
    color: #a0abb2;
}
#calendar-checkin-popup .status.completed{
    color: #8cd6ff;
}
#calendar-checkin-popup .status.active{
    color: #01DFA5;
}
#calendar-checkin-popup .status.upcoming{
    color: #ffd87a;
}
#calendar-checkin-popup .status.canceled{
    color: #ffcece;
}
.status.upcoming{
    font-family: $cera-r;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #f0b323;
}

.leftArrowReturn{
    content: none!important;
    background-image: url(../images/svg/returnArrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 19px!important;
    width: 24px!important;
    display: inline-block;
    margin-right: 5px;
}

.calendarTopHeader{
    .inlineBlock{
        vertical-align: middle;
    }
}

#calendar-checkin-popup{
    //position: fixed;
    td{
        &.status{
            text-transform: capitalize;
        }
    }

}
#qtip-calendar{
    z-index: 999 !important;
}

.fc-event {
    border-radius: 0px;
}
#calendar-popup{
    &.modal {
        text-align: center;
        padding: 0!important;
    }

    &.modal:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        position: fixed !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        .modalFooter{
            display: block;
        }
    }
}

.grayBg{
    background-color: #f2f2f2;
}

%past-event-scss {
    //width: 95%;
    border-bottom: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
}
.calendarTopHeader {
    padding: 30px 35px 30px 30px;
    .no_show &{
        //#e1e1e1
        background-color: rgba(225, 225, 225, 1)!important;
        & .status{
            color: #131e29!important;
        }
    }
    .canceled &{
        //#ffcece
        background-color: rgba(255, 206, 206, 1)!important;
        & .status{
            color: #131e29!important;
        }
    }
    .completed &{
        //background-color: #8cd6ff;
        background-color: rgba(140, 214, 255, 1)!important;
        & .status{
            color: #131e29!important;
        }
    }
    .active &{
        //#01DFA5
        background-color: rgba(1, 223, 165, 1)!important;
        & .status{
            color: #131e29!important;
        }
    }
    .upcoming &{
        //#ffd87a
        background-color: rgba(255, 216, 122, 1)!important;
        & .status{
            color: #131e29!important;
        }
    }
}

.past-event{
    //opacity: 0.4!important;
    //&:hover{
    //    opacity: 0.8!important;
    //}
    &.no_show{
        //#e1e1e1
        background-color: #f3f3f3!important;
        border-color: #f3f3f3!important;
        span {
            color: rgba(0,0,0,0.4)!important;
        }
        @extend %past-event-scss;
        .fc-list-view &{
            background-color: rgba(255, 255, 255, 0.4)!important;
        }
    }
    &.canceled{
        //#ffcece
        background-color: #ffebeb!important;
        border-color: #ffebeb!important;
        span {
            color: rgba(0,0,0,0.4)!important;
        }
        @extend %past-event-scss;
        .fc-list-view &{
            background-color: rgba(255, 255, 255, 0.4)!important;
        }
    }
    &.completed{
        //background-color: #8cd6ff;
        background-color: #d1efff!important;
        border-color: #d1efff!important;
        span {
            color: rgba(0,0,0,0.4)!important;
        }
        @extend %past-event-scss;
        .fc-list-view &{
            background-color: rgba(255, 255, 255, 0.4)!important;
        }
    }
    &.active{
        //#01DFA5
        background-color: #99f2db!important;
        border-color: #99f2db!important;
        span {
            color: rgba(0,0,0,0.4)!important;
        }
        @extend %past-event-scss;
        .fc-list-view &{
            background-color: rgba(255, 255, 255, 0.4)!important;
        }
    }
    &.upcoming{
        //#ffd87a
        background-color: #fff3d7!important;
        border-color: #fff3d7!important;
        span {
            color: rgba(0,0,0,0.4)!important;
        }
        @extend %past-event-scss;
        .fc-list-view &{
            background-color: rgba(255, 255, 255, 0.4)!important;
        }
    }
}
.fc-content.past-event {
    vertical-align: middle;
    line-height: 1;
    padding-bottom: 2px;
}

.fc-unthemed {
    .fc-list-empty{
        background-color: #ffffff;
        vertical-align: top;
        text-align: left;
        font-family: $cera-b;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a0abb2;
    }
    .fc-list-view{
        border-color: #ffffff;
    }
}
.fc-agendaDay-view {
    td.fc-widget-content{
        width: 100%;
        background-color: #ffffff;
    }
}

.fc-axis.fc-time.fc-widget-content span{
    font-family: $cera-b;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #a0abb2;
}

.infoBlock {
    .labelBlock{
        text-transform: uppercase;
    }
}
table.bookingDetail{
    td{
        padding-bottom: 15px;
        .displayBlock.price{
            td {
                padding: 0 0!important;
            }
        }
    }
}

.linkedin-icon{
    width: 65px;
    height: 65px;
    border-radius: 3px;
    background-color: #313d46;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 20px;

}

.linkedin-image{
    width: 120px;
    height: 120px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 20px;
    background-size: cover;

}
.inlineBlock.linkedin-image.default,
.inlineBlock.linkedin-image[style="background-image: url('')"]{
    background-image: url("../images/png/defaultAvatar.png")!important;
}
.booking-notes-editor{
    margin-top: 14px;
}
.booking-notes-editor + .companyEditSave {
    margin-right: 0px;
    margin-top: 22px;

}
#edit-booking-notes-button{
    position: absolute;
    right: 15px;
    top: 0;
}
#booking-notes{
    margin-top: 5px;
    
}

.extracharge_dinamic_popup {
    .form-group > select {
        min-width: 100%;
        padding: 0 15px;

    }
    &.right-modal .selectComponent::after {
        left: calc(100% - 47px);
        top: 49px;
        margin-left: inherit!important;
    }
    label.textNavy{
        font-family: "CeraGR-Regular";
        font-size: 24.2px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #272733;
        text-transform: capitalize;
    }
}