//Text links
.btn-white-normal {
    @include btn-white;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        @include btn-white-hover;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
    }
    &.disable {
        @include btn-white-disable;
        @include disable-cursor;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
        }
    }
}
.btn-white-small{
    @include btn-white-small;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        @include btn-white-small-hover;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
    }
    &.disable {
        @include btn-yellow-small-disable;
        @include disable-cursor;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
        }
    }
}
.btn-yellow-normal {
    @include btn-yellow;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        @include btn-yellow-hover;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
    }
    &.disable {
        @include btn-yellow-disable;
        @include disable-cursor;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
        }
    }
}

.btn-green-normal {
    @include btn-yellow;
    cursor: pointer;
    background-color: #2eac63;
    margin:0;
    border:0;
}

.btn-red-normal {
    @include btn-yellow;
    cursor: pointer;
    background-color: #e54d42;
    margin:0;
    border:0;
}

.btn-yellow-small{
    @include btn-yellow-small;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        @include btn-yellow-small-hover;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
    }
    &.disable {
        @include btn-yellow-small-disable;
        @include disable-cursor;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
        }
    }
}
.btn-tag-normal{
    @include btn-tag-normal;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        @include btn-tag-hover;
    }
    &:active,
    &:focus{
        outline: none;
        .x-close {
            margin-top: 2px;
            margin-bottom: -2px;
        }
    }
    &.disable {
        @include btn-tag-disable;
        @include disable-cursor;
        &:active,
        &:focus{
            outline: none;
            .x-close {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.x-close {
    width: 11px;
    height:11px;
    display: inline-block;
    background-size: cover;
    background-image: url("../images/png-elements/x-close.png");

    .disable &{
        background-image: url("../images/png-elements/x-close-gray.png");
    }
}
.inline-block-fix{
    @include inline-block;
}
span + .x-close {
    margin-left: 10px;
}
.status-yellow {
    @include status;
    color: $c-yellow;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        outline: none;
        color: $c-yellow-l;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
        &.no-anim{
            @include no-animation;
        }
    }
    &.disable {
        @include disable-cursor;
        @include status-disable;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
            margin-bottom: 0px;
            @include status-disable;
        }
    }
}
.status-gray {
    @include status;
    color: $c-gray-tag-t;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        outline: none;
        color: $c-gray-t-l;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
        &.no-anim{
            @include no-animation;
        }
    }
    &.disable {
        @include disable-cursor;
        @include status-disable;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
            margin-bottom: 0px;
            @include status-disable;
        }
    }
}
.status-white {
    @include status;
    color: $c-white;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        outline: none;
        color: #fff3d7;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
        &.no-anim{
            @include no-animation;
        }
    }
    &.disable {
        @include disable-cursor;
        @include status-disable;
        color: #ffffff;
        opacity: 0.3;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
            margin-bottom: 0px;
            @include status-disable;
            color: #ffffff;
            opacity: 0.3;
        }
    }
}
.status-red {
    @include status;
    color: $c-red-t;
    margin-bottom: 2px;
    &:hover,
    &:focus{
        outline: none;
        color: $c-red-t-l;
    }
    &:active,
    &:focus{
        outline: none;
        margin-top: 2px;
        margin-bottom: 0;
        &.no-anim{
            @include no-animation;
        }
    }
    &.disable {
        @include disable-cursor;
        @include status-disable;
        &:active,
        &:focus{
            outline: none;
            margin-top: 0px;
            margin-bottom: 0px;
            @include status-disable;
        }
    }
}
.invert {
    &.btn-white-normal {
        @include btn-white-invert;
        cursor: pointer;
        margin-bottom: 2px;
        &:hover,
        &:focus{
            @include btn-white-hover-invert;
        }
        &:active,
        &:focus{
            outline: none;
            margin-top: 2px;
            margin-bottom: 0;
        }
        &.disable {
            @include btn-white-disable-invert;
            @include disable-cursor;
            &:active,
            &:focus{
                outline: none;
                margin-top: 0px;
            }
        }
    }
    &.btn-yellow-normal {
        @include btn-yellow-invert;
        cursor: pointer;
        margin-bottom: 2px;
        &:hover,
        &:focus{
            @include btn-yellow-hover-invert;
        }
        &:active,
        &:focus{
            outline: none;
            margin-top: 2px;
            margin-bottom: 0;
        }
        &.disable {
            @include btn-yellow-disable-invert;
            @include disable-cursor;
            &:active,
            &:focus{
                outline: none;
                margin-top: 0px;
            }
        }
    }
}

