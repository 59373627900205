.w-header{
    min-height: 80px;
    padding-right: 30px;
    .w-logo-holder {
        background-color: $c-white;
        padding-left: 25px;
        padding-right: 25px;;
        line-height: 80px;
        wodth: 100px;
        height: 80px;
        vertical-align: middle;
        .w-logo-img{
            width: 46px;
            height: 31px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../images/menu-elements/w-logo-image.svg");
        }
    }
    .w-search{
        padding: 0 30px;
        width: calc(100% - 190px);
    }
    .w-buttons-holder{
        .w-notification{
            width: 29px;
            height: 35px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../images/menu-elements/w-notification-no.jpg");
            &.new-notification{
                background-image: url("../images/menu-elements/w-notification-dot.jpg");
            }
            &.active{
                background-image: url("../images/menu-elements/w-notification-hover.jpg");
            }
        }
    }
}
